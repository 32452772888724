import { ChangeDetectorRef, Component, OnInit, } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-payment-successfull',
  templateUrl: './payment-successfull.component.html',
  styleUrls: ['./payment-successfull.component.css']
})
export class PaymentSuccessfullComponent implements OnInit {
  newsLetterForm: FormGroup;
  showNewletter: boolean = true;

  constructor(public configApiService: ConfigApiServiceService, private fb: FormBuilder, public dialogRef: MatDialogRef<PaymentSuccessfullComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public changeDetectorRef: ChangeDetectorRef , public toastr: ToastrService) {
    this.newsLetterForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
    });
  }

  ngOnInit() {
  }

  /**
 * Submit contact Form
 */
  submit() {
    if (this.newsLetterForm.valid) {
      this.SaveForm()
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.newsLetterForm);
    }
  }

  /**
* Save Contact Form
*/
  SaveForm() {
    let postData = {
      Name: this.newsLetterForm.value.name,
      Email_Id: this.newsLetterForm.value.email,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.lhcNewsLetter.params, postData);
    this.configApiService.pushRequest(objData, this.reqSucess)
  }

  reqSucess = ((resData) => {
    if (resData) {
      this.showNewletter = false;
    }
  })

  /**
* Validation for form 
* @param formGroup 
*/
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
    // this.router.navigate(['/'])
  }
}

