import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SuccessPaymentComponent } from '../success-payment/success-payment/success-payment.component';
import { ToastrService } from 'ngx-toastr';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

declare var Stripe: any;
const defaultValue = 0
const invoiceNumber = '20230000';
@Component({
  selector: 'app-eita-registration',
  templateUrl: './eita-registration.component.html',
  styleUrls: ['./eita-registration.component.css']
})
export class EitaRegistrationComponent implements OnInit {
  @ViewChild('dialogRef', { static: false })
  dialogRef!: TemplateRef<any>
  eita_RegistrationForm: FormGroup;
  event_Id: any;
  event_price: any;
  event_Name: any;
  Registration_Id: any = [];
  currentEvent: any = 'Eita';
  dateFormat = 'yyyy-MM-dd';
  currentDate = new Date();
  showQRCode: boolean;
  private stripe: any;
  StripePaymentUrl: any = 'https://drive.prodcast.cloud:3784/Stripepayment'; // Live_Url
  //StripePaymentUrl: any = 'https://localhost:3784/Stripepayment'; // Local_Url
  sendUrl: any = 'https://drive.prodcast.cloud:3784/sendMail'; // Live_Url
  // sendUrl: any = 'https://localhost:3784/sendMail'; // Local_Url
  adminEmailId: any = "hello@thelovehopecompany.com";
  invoiceNo: any;
  eitaEvent: any;
  selectPayment: any;
  checkPaymentmethod: boolean = false;
  eitaDetails: any;
  showUpiId: boolean = false;
  updateInvoiceNo: number;
  //sendEventSMSUrl: any = 'https://localhost:3784/sendEventSMS'; // Local_Url'
  sendEventSMSUrl: any = 'https://drive.prodcast.cloud:3784/sendEventSMS'; // Live_Url'
  whatsAppLink: any;
  event_Date: any;
  event_Time: any;
  locationMaster: any;
  no_Of_child: any;
  eventPrice: number;
  duplicateName: boolean;
  enableMobileError: boolean;
  showPaymentScreen: boolean = false;
  childName: any = [];
  ccEmailId: any = "admin@thelovehopecompany.com";
  paymentStatus: any = "UnPaid";
  phonePeUrl: any = 'https://drive.prodcast.cloud:3784/phonepeUrl'; // Live_Url
  // phonePeUrl: any = 'https://localhost:3784/phonepeUrl'; // Local_Url
  checkoutUrl: any;
  Event_Registration_Id: any;
  parentName: any;
  name: any;
  email: any;
  grade: any;
  phone: any;
  referralEita: any;
  age: any;
  modeOfPayment: any;
  description: any;
  additionalComments: any;
  school: any;

  constructor(private http: HttpClient, private router: Router, private fb: FormBuilder, public configApiService: ConfigApiServiceService, public datePipe: DatePipe, public dialog: MatDialog, public toastr: ToastrService) {
    this.stripe = Stripe('pk_live_51NLPLuSBD5puc8V6VxIWY56IOkpKPhAjvYXlnCRGHyfYs9ani4GDozu4ckp8EDgMXlRM1U5d7kkkbnvxEphaKSRN00PX5pFIRa'); //Live Keys
    //this.stripe = Stripe('pk_test_51NLNLISAvZBYHudzm5UsIdpwQQz96O1qlBSF9IIjPG7T93GqpjgJx2sZFeVSkwfP2DghhjfydoA4g7a6VU1N2ySL004IZBsWYw'); //Local Keys
  }

  ngOnInit() {
    this.eita_RegistrationForm = this.fb.group({
      children: this.fb.array([this.createChildFormGroup()]),
      parent_one: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      sourceReferral: ['', [Validators.required]],
      description: ['',],
      additional_Comments: ['',],
    });

    if (localStorage.getItem('EitaEvent') != null && localStorage.getItem('EitaEvent') != 'undefined') {
      this.eitaEvent = localStorage.getItem('EitaEvent');
      this.eitaEvent = JSON.parse(localStorage.getItem('EitaEvent'))
      this.event_Id = this.eitaEvent[defaultValue].Event_Master_1_Id;
      this.event_price = this.eitaEvent[defaultValue].Event_Price;
      this.event_Name = this.eitaEvent[defaultValue].Event_Name;
      this.whatsAppLink = this.eitaEvent[defaultValue].Whats_App_Link;
      this.event_Date = this.eitaEvent[defaultValue].Event_Date;
      this.event_Time = this.eitaEvent[defaultValue].Event_Time;
    }
    if (this.event_price > defaultValue) {
      this.showPaymentScreen = true;
    }
    this.getTransaction();
    this.getLocation();
    localStorage.removeItem('CustomerDeatils');
    localStorage.removeItem('eventsDeatils');
    localStorage.removeItem('sessionDeatils');
  }

  /**
  *method for Validate Name
  **/
  validateName() {
    const childrenData = this.eita_RegistrationForm.controls.children.value;
    if (childrenData.length >= 2) {
      const duplicateChildNames = childrenData.filter((child: any, index: number, array: any[]) => {
        const currentName = child['name'].toLowerCase();
        if (currentName != "") {
          return array.findIndex((c: any) => c !== child && c['name'].toLowerCase() === currentName) !== -1;
        }
      });
      const hasDuplicateNames = duplicateChildNames.length > 0;
      if (hasDuplicateNames == true) {
        let title = "Alert"
        let message = "Child name cannot be same "
        this.openAlert(message, title, (result) => {
        })
        this.duplicateName = true;
      }
      else {
        this.duplicateName = false;
      }
    }
  }


  /**
  * submit Eita Registration
  */
  submit() {
    if (this.eita_RegistrationForm.valid && this.duplicateName != true && this.enableMobileError != true) {
      const childrenData = this.eita_RegistrationForm.controls.children.value;
      console.log(childrenData, "childrenData")
      this.no_Of_child = childrenData.length;
      childrenData.forEach(element => {
        this.childName.push(element.name)
        let postData = {
          "Event_Name": this.event_Name || "",
          "Name": element.name || "",
          "Age": element.age || "",
          "Grade": element.grade || "",
          "School": element.school || "",
          "ParentName_one": this.eita_RegistrationForm.controls.parent_one.value || "",
          "ParentName_two": "",
          "Mobile_no": this.eita_RegistrationForm.controls.phone.value || "",
          "Email": this.eita_RegistrationForm.controls.email.value || "",
          "Referral_eita": this.eita_RegistrationForm.controls.sourceReferral.value || "",
          "Description": this.eita_RegistrationForm.controls.description.value || "",
          "Additional_comments": this.eita_RegistrationForm.controls.additional_Comments.value || "",
          "Event_id": this.event_Id || 0,
          "Event_amount": this.event_price || 0,
          "Mode_of_Payment": "",
          "Payment_Status": this.paymentStatus || "",
        }
        this.eitaDetails = postData;
        let objData = Object.assign({}, this.configApiService.lhcApi.postEitaRegistration.params, postData);
        this.configApiService.pushRequest(objData, this.resGroupSuccess)
      });
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.eita_RegistrationForm);
    }
  }

  /**
  * Group Therapy Form Submit response
  **/
  resGroupSuccess = ((resData) => {
    if (resData) {
      console.log(resData)
      let Registration_Id = resData['Event_Registration5146'].Event_Registration_Id
      this.Registration_Id.push(Registration_Id)
      console.log(this.Registration_Id, "registrationid")
      if (this.event_price > defaultValue) {
        if (this.no_Of_child == this.Registration_Id.length) {
          this.eitaPhonePePayment()
          // if (this.selectPayment == "paybyCard") {
          //   this.paymentCheckout();
          // }
          // if (this.selectPayment == "paybyUPI") {
          //   this.openUpiScreen();
          //   this.updateEitaTransaction();
          // }
        }
      } else {
        this.configApiService.showEitaReg = true;
        this.sendEventSms();
        this.sendMail(this.eitaDetails.Email);
        this.openEitaReg();
      }
      // this.updateTransaction();
    }
  })

  /**
   * Validation for form 
   * @param formGroup 
   */
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(childGroup => {
          this.markFormGroupAsTouched(childGroup as FormGroup);
        });
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
    * show payment screen popup
    */
  paymentScreen() {
    this.showQRCode = false;
    this.opendialog();
  }

  /**
  * get Transaction Summary
  */
  getTransaction() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pushLhc_Invoice.params, (respData) => {
      if (respData.length != defaultValue) {
        if (respData[0].LatestInvoiceNumber) {
          this.invoiceNo = respData[0].LatestInvoiceNumber;
        } else {
          this.invoiceNo = invoiceNumber;
        }
      }
    });
  }

  /***
  * method for openpoup 
  */
  opendialog() {
    this.dialog.open(this.dialogRef, { disableClose: true, })
  }

  /***
  * Open UPI popup screen  
  */
  openUpiScreen() {
    this.showUpiId = true;
  }

  /***
 * method for closepopup
 */
  close() {
    this.dialog.closeAll()
    this.router.navigate(['/']);
  }

  /**
  * Payment mode events
  */
  enablepayment(e) {
    this.selectPayment = e.target.value;
    console.log(this.selectPayment, "selectPayment")
    if (this.selectPayment == "paybyUPI") {
      this.openUpiScreen();
    } else {
      this.showUpiId = false;
    }
    this.checkPaymentmethod = true;
  }

  /**
   * Post Stripe Payment
   */
  paymentCheckout() {
    this.eventPrice = this.event_price * this.no_Of_child;
    let clientName = this.eita_RegistrationForm.controls.parent_one.value + "- Child " + this.no_Of_child;
    const customer = {
      Events_Id: this.event_Id || 0,
      Event_Name: this.event_Name || "",
      clientName: clientName || "",
      Group_Type: this.currentEvent || "",
      Registration_Id: this.Registration_Id || 0,
      therapyPrice: this.eventPrice || 0,
      invoiceNumber: parseInt(this.invoiceNo) + 1,
      phnno: this.eita_RegistrationForm.controls.phone.value || "",
      emailId: this.eita_RegistrationForm.controls.email.value || "",
      therapyTypeName: this.event_Name || "",
      event_Date: this.event_Date,
      event_Time: this.event_Time,
      Address: this.locationMaster[0].Address,
      location: this.locationMaster[0].Location_Link,
      whatsAppLink: this.whatsAppLink,
      no_Of_child: this.no_Of_child,
      eventPrice: this.event_price,
      ParentName: this.eita_RegistrationForm.controls.parent_one.value || "",
      childName: this.childName,
      eitaDetails: this.eitaDetails,
      currentEvent: this.currentEvent,
    };
    localStorage.setItem('eitaDeatils', JSON.stringify(customer));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http.post(this.StripePaymentUrl, { customer }, { headers })
      .subscribe(
        (session: any) => {
          console.log(session)
          return this.stripe.redirectToCheckout({ sessionId: session.id })
            .then((result: any) => {
              if (result.error) {
                alert(result.error.message);
              }
            })
            .catch((error: any) => {
              console.error('Error:', error);
            });
        },
        (error: any) => {
          console.error('Error:', error);
        }
      );
  }

  /**
  * Post PhonePe Payment
  */
  eitaPhonePePayment() {
    this.eventPrice = this.event_price * this.no_Of_child;
    let clientName = this.eita_RegistrationForm.controls.parent_one.value + "- Child " + this.no_Of_child;
    const customer = {
      Events_Id: this.event_Id || 0,
      Event_Name: this.event_Name || "",
      clientName: clientName || "",
      Group_Type: this.currentEvent || "",
      Registration_Id: this.Registration_Id || 0,
      therapyPrice: this.eventPrice || 0,
      invoiceNumber: parseInt(this.invoiceNo) + 1,
      phnno: this.eita_RegistrationForm.controls.phone.value || "",
      emailId: this.eita_RegistrationForm.controls.email.value || "",
      therapyTypeName: this.event_Name || "",
      event_Date: this.event_Date,
      event_Time: this.event_Time,
      Address: this.locationMaster[0].Address,
      location: this.locationMaster[0].Location_Link,
      whatsAppLink: this.whatsAppLink,
      no_Of_child: this.no_Of_child,
      eventPrice: this.event_price,
      ParentName: this.eita_RegistrationForm.controls.parent_one.value || "",
      childName: this.childName,
      eitaDetails: this.eitaDetails,
      currentEvent: this.currentEvent,
    };
    localStorage.setItem('eitaDeatils', JSON.stringify(customer));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http.post(this.phonePeUrl, { customer }, { headers })
      .subscribe(
        (checkoutUrl: any) => {
          this.checkoutUrl = checkoutUrl.URL;
          window.location.href = this.checkoutUrl;
          console.log(window.location.href)
        },
      );
  }
  /**
  * Copy Function
  */
  copyInputMessage() {
    var textToCopy = document.getElementById("myInput").innerText.split(':')[1].trim();

    /* Create a temporary input element */
    var tempInput = document.createElement("input");

    /* Assign the text to be copied to the input element */
    tempInput.value = textToCopy;

    /* Append the input element to the document body */
    document.body.appendChild(tempInput);

    /* Select the text inside the input element */
    tempInput.select();

    /* Copy the selected text to the clipboard */
    document.execCommand("copy");

    /* Remove the temporary input element */
    document.body.removeChild(tempInput);

    /* Alert the user that the text has been copied */
    alert("Text copied to clipboard");
  }

  /**
  * Update Eita Transaction
  */
  updateEitaTransaction() {
    this.updateInvoiceNo = parseInt(this.invoiceNo) + 1
    this.eventPrice = this.event_price * this.no_Of_child;
    let clientName = this.eita_RegistrationForm.controls.parent_one.value + "- Child " + this.no_Of_child;

    let postTransaction = {
      "Trans_Ref_id": "",
      "Events_Id": this.eitaDetails.Events_Id || 0,
      "Event_Name": this.eitaDetails.Event_Name || "",
      "Registration_Name": clientName || "",
      "Group_Type": this.currentEvent || "",
      "Registration_Id": this.Registration_Id || 0,
      "Gw_Trans_Ref_id": 0,
      "Gw_trans_Status": 0,
      "Date_of_transacion": this.datePipe.transform(this.currentDate, this.dateFormat),
      "Amount_paid": this.eventPrice || 0,
      "Coupon": "",
      "Modeofpayment": "4",
      "Transaction_Status": "",
      "Invoice_no": this.updateInvoiceNo || "",
    }
    this.configApiService.etiaTransaction = postTransaction;
    let objData = Object.assign({}, this.configApiService.lhcApi.postEventTransaction.params, postTransaction);
    this.configApiService.pushRequest(objData, this.resTransactionSuccess)
  }

  /**
  * comfort Spaces Form Submit response
  */
  resTransactionSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
      this.configApiService.showEitaUpiSuccess = true;
      this.updateInvoice(this.updateInvoiceNo);
      this.sendEventSms();
      this.sendMail(this.eitaDetails.Email);
      this.openEitaSuccess();
    }
  })

  /***
  * Method for open UPI Payment Registration  success
  */
  openEitaSuccess(): void {
    var params = {
      eita: this.eitaDetails,
      invoiceNo: this.updateInvoiceNo,
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***
  * Method for open Registration  success
  */
  openEitaReg(): void {
    var params = {
      eita: this.eitaDetails,
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
  * Update Invoice number to master
  */
  updateInvoice(invoice) {
    let invoiceData = {
      LatestInvoiceNumber: invoice,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pullLhc_Invoice.params, invoiceData);
    this.configApiService.pushRequest(objData, this.resInovice)
  }

  /**
  * Invoice Response Data
  */
  resInovice = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Invoice Updated")
    }
  })

  /**
  * send mail through mail
  */
  sendMail(mailId) {
    let mailScheduler = {};
    var FROMID = this.adminEmailId;
    var TOID = mailId;
    var CC = this.adminEmailId;
    var BCC = this.ccEmailId
    var location;
    var address;
    if (this.locationMaster[0].Location_Link) {
      location = this.locationMaster[0].Location_Link;
      address = this.locationMaster[0].Address;
    }
    var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.eitaDetails.ParentName_one + "</h4><p> Thank you for registering for " + this.event_Name + ". Your payment is received and your registration has been confirmed. </p><p>We look forward to seeing " + this.childName + " on,</p><br>Date : " + this.event_Date + " <br>Time : " + this.event_Time + "  <br>Location : <a href='" + location + "'>" + address + "</a> <p>For any further queries, please reach out to us at +91 9677029999</p><div>Warm regards,<br>The Love Hope Company</div><br><br><p>Join our WhatsApp Community for more updates: <br><a href='" + this.whatsAppLink + "'>" + this.whatsAppLink + "</a> </p></body></html>"
    var mailSubject = "Registration Successful- The Love Hope Company!";
    mailScheduler = { 'TOID': TOID, 'FROMID': FROMID, 'CC': CC, "BCC": BCC, 'BODY': _mailhtmlbody, 'ShareType': 1, 'SUBJECT': mailSubject };
    let objPushParams = Object.assign({}, this.configApiService.lhcApi.pushMailShareUser.params, mailScheduler);
    this.configApiService.pushRequest(objPushParams, this.mailSentResponseStatus);
  }

  mailSentResponseStatus = ((responsedata) => {
    if (responsedata == "SUCCESS") {
      console.log(responsedata, "responsedata")
    }
  });

  /**
  * Send Events SMS api
  */
  sendEventSms() {
    var param = {
      TOID: this.eitaDetails.Mobile_no,
      Event_Name: this.eitaDetails.Event_Name,
      Event_Date: this.event_Date,
    };
    var obj = {};
    obj['data'] = JSON.stringify(param);
    var pa = JSON.stringify(obj);
    this.http.post(this.sendEventSMSUrl, pa).subscribe((response: any) => {
      console.log(response, "response")
    })
  }

  /**
   * Submit contact Form
   */
  getLocation() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullLocationMaster.params, (respData) => {
      if (respData.length != defaultValue) {
        this.locationMaster = respData;
      }
    });
  }

  /**
  *method for validate customer
  */
  fetchCustomer(e) {
    if (e.target.value) {
      var params = {
        Mobile_no: e.target.value
      }
      let objData = Object.assign({}, this.configApiService.lhcApi.pullEitaCustomer.params, params);
      this.configApiService.pullRequest(objData, (respData) => {
        if (respData.length != defaultValue) {

          /**
           * Check Event and unpaid payment status 
           */
          let duplicateNumber = respData.filter(e => {
            return e.Event_id == this.event_Id && e.Payment_Status == "UnPaid"
          })

          /**
           * Check Event and suucess payment status 
           */
          let duplicateNumberPaid = respData.filter(e => {
            return e.Event_Id == this.event_Id && e.Payment_Status == "PAYMENT_SUCCESS" || e.Payment_Status == ""
          })

          if (duplicateNumber.length > 0) {
            this.enableMobileError = true;
            let title = "Event"
            let message = "This Number already Registered are you Retry the payment ?"
            this.openAlert(message, title, (result) => {
              if (result == "yes") {
                this.enableMobileError = false;
                this.Event_Registration_Id = duplicateNumber[defaultValue].Event_Registration_Id;
                this.event_Id = duplicateNumber[defaultValue].Event_id;
                this.event_Name = duplicateNumber[defaultValue].Event_Name;
                this.parentName = duplicateNumber[defaultValue].ParentName_one;
                this.name = duplicateNumber[defaultValue].Name;
                this.email = duplicateNumber[defaultValue].Email;
                this.grade = duplicateNumber[defaultValue].Grade;
                this.phone = duplicateNumber[defaultValue].Mobile_no;
                this.referralEita = duplicateNumber[defaultValue].Referral_eita;
                this.age = duplicateNumber[defaultValue].Age;
                this.modeOfPayment = duplicateNumber[defaultValue].Mode_of_Payment;
                this.description = duplicateNumber[defaultValue].Description;
                this.additionalComments = duplicateNumber[defaultValue].Additional_comments
                this.school = duplicateNumber[defaultValue].School
                this.eventPrice = duplicateNumber[defaultValue].Event_amount;
                this.paymentStatus = duplicateNumber[defaultValue].Payment_Status;
              }
            })
          } else {
            this.enableMobileError = false;
          }
          if (duplicateNumberPaid.length > defaultValue) {
            this.enableMobileError = true;
            let title = "Alert"
            let message = "This Number already Registered"
            this.openAlert(message, title, (result) => {
            })
          }
        } else {
          this.enableMobileError = false;
        }
      })
    }
  }

  /***
   * Method for open Alert screen
   */
  openAlert(message, title, callback): void {
    const dialogRef = this.dialog.open(AlertMessageComponent, {
      data: {
        message: message,
        title: title
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    });
  }

  /**
   * method for create Child FormGroup
   */

  get children() {
    return this.eita_RegistrationForm.get('children') as FormArray;
  }

  /**
    * method for create Child FormGroup
    */
  createChildFormGroup() {
    return this.fb.group({
      name: ['', Validators.required],
      age: ['', Validators.required],
      grade: ['', Validators.required],
      school: ['', Validators.required]
    });
  }

  /**
  * Method for add multiple child
  */
  addChild() {
    this.children.push(this.createChildFormGroup());
    const childrenData = this.eita_RegistrationForm.controls.children.value;
    this.no_Of_child = childrenData.length;
    this.eventPrice = this.event_price * this.no_Of_child;
  }

  /**
  * Method for Remove child
  */
  removeChild(index: number) {
    this.children.removeAt(index);
    const childrenData = this.eita_RegistrationForm.controls.children.value;
    this.no_Of_child = childrenData.length;
    this.eventPrice = this.event_price * this.no_Of_child;
  }
}
