import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './header/header.component';
import { EitaComponent } from './eita/eita.component';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { RegistrationformComponent } from './registrationform/registrationform.component';
import { LoveHopeCircleComponent } from './love-hope-circle/love-hope-circle.component';
import { PaymentSuccessfullComponent } from './payment-successfull/payment-successfull.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { SuccessPaymentComponent } from './success-payment/success-payment/success-payment.component';
import { EitaRegistrationComponent } from './eita-registration/eita-registration.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AdminEventsRegistrationComponent } from './admin-events-registration/admin-events-registration.component';
import { AdminEitaRegistrationComponent } from './admin-eita-registration/admin-eita-registration.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'eita', component: EitaComponent },
  { path: 'contact', component: ContactpageComponent },
  { path: 'register', component: RegistrationformComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'lovehope', component: LoveHopeCircleComponent },
  { path: 'success', component: PaymentSuccessfullComponent },
  { path: 'viewPdf', component: ViewPdfComponent },
  { path: 'paymentSuccess', component: SuccessPaymentComponent },
  { path: 'eitaRegistration', component: EitaRegistrationComponent },
  { path: 'printInvoice', component: PrintInvoiceComponent },
  { path: 'adminregistration', component: AdminRegistrationComponent },
  { path: 'admineventsregistration', component: AdminEventsRegistrationComponent },
  { path: 'admineitaregistration', component:  AdminEitaRegistrationComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
