import { Component, OnInit ,ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewPdfComponent implements OnInit {
  pdfUrl: any;
  public pdfSrc = "";
  zoomValue: number = 1; // Initial zoom value
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    let url = this.data.url ? this.data.url + "#toolbar=0" : this.data.url;
    this.pdfUrl = url
    this.uploadFile(this.pdfUrl)
  }

  ngOnInit() {
    const matDielog = document.getElementsByClassName('mat-dialog-container',) as HTMLCollectionOf<HTMLElement>;
    if (matDielog) {
      for (let i = 0; i <= 0; i++) {
        matDielog[i].style.padding = "unset";
      }
    }
  }

  public uploadFile(pdfUrl: string) {
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.pdfSrc = e.target.result;
        };
        reader.readAsArrayBuffer(blob);
      })
      .catch(error => {
        console.error('Error fetching PDF file:', error);
      });
  }
}