import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigApiServiceService } from 'src/app/providers/config-api-service.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.css']
})
export class SuccessPaymentComponent implements OnInit {
  successData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public configApiService: ConfigApiServiceService, public dialogRef: MatDialogRef<SuccessPaymentComponent>,) { }

  ngOnInit() {
    this.successData = this.data
    console.log(this.successData, "this.successData")
  }

  close(): void {
    this.dialogRef.close();
    this.router.navigate(['/'])
  }

  printPage() {
    this.dialogRef.close();
    setTimeout(() => {
      this.router.navigate(['/printInvoice'])
    }, 1000);
  }

}
