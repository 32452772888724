import { Component, OnInit } from '@angular/core';
import { toWords } from 'number-to-words';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css']
})
export class PrintInvoiceComponent implements OnInit {
  customer: any;
  currentDate: Date;
  therapyPriceWord: any;
  eitaData: any;
  showchild: boolean=false;
  no_of_child: any;

  constructor(public configApiService: ConfigApiServiceService,public router: Router) { }

  ngOnInit() {
    this.currentDate = new Date()
    if (localStorage.getItem('CustomerDeatils') != null && localStorage.getItem('CustomerDeatils') != 'undefined') {
      this.customer = localStorage.getItem('CustomerDeatils');
      this.customer = JSON.parse(localStorage.getItem('CustomerDeatils'))
      console.log(this.customer, "customer")
      this.therapyPriceWord = toWords(this.customer.therapyPrice);
      this.configApiService.mailToClientReg = true;
      this.showchild =false;
    }
    if (localStorage.getItem('eitaDeatils') != null && localStorage.getItem('eitaDeatils') != 'undefined') {
      this.eitaData = localStorage.getItem('eitaDeatils');
      this.eitaData = JSON.parse(localStorage.getItem('eitaDeatils'))
      console.log(this.eitaData, "Eita")
      this.therapyPriceWord = toWords(this.configApiService.etiaTransaction.Amount_paid);
      this.configApiService.mailToClientReg = false;
      if (this.eitaData.clientName.includes("Child")) {
        this.showchild =true
      }
    }
    if (localStorage.getItem('eventsDeatils') != null && localStorage.getItem('eventsDeatils') != 'undefined') {
      this.eitaData = localStorage.getItem('eventsDeatils');
      this.eitaData = JSON.parse(localStorage.getItem('eventsDeatils'))
      console.log(this.eitaData, "eventsDeatils")
      this.therapyPriceWord = toWords(this.configApiService.etiaTransaction.Amount_paid);
      this.configApiService.mailToClientReg = false;
      this.showchild =false;
    }
    setTimeout(() => {
      window.print();
    }, 1000);
  }
  back(){
    this.router.navigate(['/'])
  }
}
