import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SuccessPaymentComponent } from '../success-payment/success-payment/success-payment.component';
import { ToastrService } from 'ngx-toastr';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

const defaultValue = 0;
const invoiceNumber = '20230000';
declare var Stripe: any;

@Component({
  selector: 'app-love-hope-circle',
  templateUrl: './love-hope-circle.component.html',
  styleUrls: ['./love-hope-circle.component.css']
})
export class LoveHopeCircleComponent implements OnInit {
  @ViewChild('dialogRef', { static: false })
  dialogRef!: TemplateRef<any>
  Grouptheraphyenable: boolean = false;
  supportgroupEnable: boolean = false;
  comfortSpacesEnable: boolean = false;
  checkboxChecked: boolean = false;
  currentEvent: any = 'SupportGroup';
  supportGroup: any;
  supportForm: FormGroup;
  groupForm: FormGroup;
  comfortForm: FormGroup;
  event_price: any;
  event_Id: any;
  event_Name: any;
  showQRCode: boolean;
  Registration_Id: any;
  dateFormat = 'yyyy-MM-dd';
  currentDate = new Date();
  showgif: boolean;
  group_showgif: boolean;
  comfort_showgif: boolean;
  showForm: boolean;
  group_showForm: boolean;
  comfort_showForm: boolean;
  currentSlide = defaultValue;
  invoiceNo: any;
  private stripe: any;
  StripePaymentUrl: any = 'https://drive.prodcast.cloud:3784/Stripepayment'; // Live_Url
  //StripePaymentUrl: any = 'https://localhost:3784/Stripepayment'; // Local_Url
  eventPrice: string;
  disableSlideIcon: any;
  sendUrl: any = 'https://drive.prodcast.cloud:3784/sendMail'; // Live_Url
  //sendUrl: any = 'https://localhost:3784/sendMail'; // Local_Url
  //sendEventSMSUrl: any = 'https://localhost:3784/sendEventSMS'; // Local_Url'
  sendEventSMSUrl: any = 'https://drive.prodcast.cloud:3784/sendEventSMS'; // Live_Url'
  adminEmailId: any = "hello@thelovehopecompany.com";
  ccEmailId: any = "admin@thelovehopecompany.com"
  selectPayment: any;
  checkPaymentmethod: boolean = false;
  eitaDetails: any;
  showUpiId: boolean = false;
  updateInvoiceNo: number;
  documentList: any;
  locationMaster: any;
  whatsAppLink: any;
  event_Date: any;
  event_Time: any;
  enableMobileError: boolean;
  showPaymentScreen: boolean = false;
  paymentStatus: any = "UnPaid";
  phonePeUrl: any = 'https://drive.prodcast.cloud:3784/phonepeUrl'; // Live_Url
  //phonePeUrl: any = 'https://localhost:3784/phonepeUrl'; // Local_Url
  checkoutUrl: any;
  checkoutScreen: boolean = false;
  comfortSpaceRegistation_1_Id: any;
  eventId: any;
  eventName: any;
  name: any;
  email: any;
  age: any;
  phone: any;
  gender: any;
  location: any;
  modeOfPayment: any;
  groupDescription: any;
  additionalComments: any;
  eventsAmount: any;
  therapyBefore: any;
  anyProir: any;
  Support_Groups_Registation_1_Id: any;
  Group_Therapy_Registation_1_Id: string;

  constructor(private http: HttpClient, private router: Router, public configApiService: ConfigApiServiceService, public datePipe: DatePipe, private fb: FormBuilder, public dialog: MatDialog, public toastr: ToastrService) {
    this.stripe = Stripe('pk_live_51NLPLuSBD5puc8V6VxIWY56IOkpKPhAjvYXlnCRGHyfYs9ani4GDozu4ckp8EDgMXlRM1U5d7kkkbnvxEphaKSRN00PX5pFIRa'); //Live Keys
    //this.stripe = Stripe('pk_test_51NLNLISAvZBYHudzm5UsIdpwQQz96O1qlBSF9IIjPG7T93GqpjgJx2sZFeVSkwfP2DghhjfydoA4g7a6VU1N2ySL004IZBsWYw'); //Local Keys
    this.supportForm = this.fb.group({
      name: ['', [Validators.required]],
      age: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      gender: ['', [Validators.required]],
      location: ['', [Validators.required]],
      Group_Description: [''],
      Additional_Comments: [''],
    })

    this.groupForm = this.fb.group({
      group_name: ['', [Validators.required]],
      group_age: ['', [Validators.required]],
      group_phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      group_email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      group_gender: ['', [Validators.required]],
      group_location: ['', [Validators.required]],
      group_Group_Description: [''],
      group_Additional_Comments: [''],
      group_Any_Proir: [''],
      group_Therapy_before: ['']
    })
    this.comfortForm = this.fb.group({
      comfort_name: ['', [Validators.required]],
      comfort_age: ['', [Validators.required]],
      comfort_phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      comfort_email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      comfort_gender: ['', [Validators.required]],
      comfort_location: ['', [Validators.required]],
      comfort_Group_Description: [''],
      comfort_Additional_Comments: [''],
    });
  }

  ngOnInit() {
    this.getEvents();
    this.getDocumentationList();
    this.currentSlide = defaultValue;
    this.getTransaction();
    this.getLocation();
    localStorage.removeItem('CustomerDeatils');
    localStorage.removeItem('eitaDeatils');
    localStorage.removeItem('sessionDeatils');
  }

  /**
  *show event based on group
  */
  groupEnable(e) {
    if (e == 'SupportGroup') {
      this.currentSlide = defaultValue;
      this.currentEvent = e;
      if (this.configApiService.EventsList && this.configApiService.EventsList.length > 0) {
        let supportGroup = this.configApiService.EventsList.filter(item => {
          const eventEndDate = new Date(item.Event_End_Date + 'T23:59:59');
          return item.Event_Type === this.currentEvent &&
            item.Event_Active === "true" &&
            eventEndDate >= this.currentDate;
        });
        this.supportGroup = supportGroup.sort((a, b) => a.Hierchy - b.Hierchy);
        console.log(this.supportGroup, "supportGroup")
        if (this.supportGroup.length > 0) {
          this.supportGroup.length < 2 ? this.disableSlideIcon = false : this.disableSlideIcon = true
          this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
          this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
          this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
          this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
          this.event_price = this.supportGroup[this.currentSlide].Event_Price;
          this.eventPrice = '₹ ' + this.event_price;
          this.event_Name = this.supportGroup[this.currentSlide].Event_Name
          this.showForm = true;
        }
        else {
          this.showgif = true;
          this.event_price = ""
          this.eventPrice = ""
          this.event_Name = ""
          this.showForm = false;
        }
      }
      else {
        this.showgif = true
        this.showForm = false;
      }
      this.supportgroupEnable = true;
    } else {
      this.supportgroupEnable = false;
    }
    if (e == 'GroupTherapy') {
      this.currentSlide = defaultValue;
      this.currentEvent = e;
      if (this.configApiService.EventsList && this.configApiService.EventsList.length > 0) {
        let supportGroup = this.configApiService.EventsList.filter(item => {
          const eventEndDate = new Date(item.Event_End_Date + 'T23:59:59');
          return item.Event_Type === this.currentEvent &&
            item.Event_Active === "true" &&
            eventEndDate >= this.currentDate;
        });
        this.supportGroup = supportGroup.sort((a, b) => a.Hierchy - b.Hierchy);
        console.log(this.supportGroup, "GroupTherapy")
        if (this.supportGroup.length > 0) {
          this.supportGroup.length < 2 ? this.disableSlideIcon = false : this.disableSlideIcon = true
          this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
          this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
          this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
          this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
          this.event_price = this.supportGroup[this.currentSlide].Event_Price;
          this.eventPrice = '₹ ' + this.event_price;
          this.event_Name = this.supportGroup[this.currentSlide].Event_Name;
          this.group_showForm = true;
        }
        else {
          this.group_showgif = true;
          this.event_price = ""
          this.eventPrice = ""
          this.event_Name = ""
          this.group_showForm = false;
        }
      }
      else {
        this.group_showgif = true;
        this.group_showForm = false;
      }
      this.Grouptheraphyenable = true;
    } else {
      this.Grouptheraphyenable = false;
    }
    if (e == 'ComfortSpaces') {
      this.currentSlide = defaultValue;
      this.currentEvent = e;
      if (this.configApiService.EventsList && this.configApiService.EventsList.length > 0) {
        let supportGroup = this.configApiService.EventsList.filter(item => {
          const eventEndDate = new Date(item.Event_End_Date + 'T23:59:59');
          return item.Event_Type === this.currentEvent &&
            item.Event_Active === "true" &&
            eventEndDate >= this.currentDate;
        });
        this.supportGroup = supportGroup.sort((a, b) => a.Hierchy - b.Hierchy);
        console.log(this.supportGroup, "ComfortSpaces")
        if (this.supportGroup.length > 0) {
          this.supportGroup.length < 2 ? this.disableSlideIcon = false : this.disableSlideIcon = true
          this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
          this.event_price = this.supportGroup[this.currentSlide].Event_Price;
          this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
          this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
          this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
          this.eventPrice = '₹ ' + this.event_price;
          this.event_Name = this.supportGroup[this.currentSlide].Event_Name;
          this.comfort_showForm = true;
        }
        else {
          this.comfort_showgif = true;
          this.event_price = ""
          this.eventPrice = ""
          this.event_Name = ""
          this.comfort_showForm = false;
        }
      }
      else {
        this.comfort_showgif = true;
        this.comfort_showForm = false;
      }
      this.comfortSpacesEnable = true;
    } else {
      this.comfortSpacesEnable = false;
    }
    if (this.event_price > defaultValue) {
      this.showPaymentScreen = true;
    }
  }


  /***
   * Method for open Alert screen
   */
  openAlert(message, title, callback): void {
    const dialogRef = this.dialog.open(AlertMessageComponent, {
      data: {
        message: message,
        title: title
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    });
  }

  /**
  *  toggleButton
  */
  toggleButton() {
    this.checkboxChecked = !this.checkboxChecked;
  }

  /**
  * get Document List
  */
  getDocumentationList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullDocument.params, (respData) => {
      if (respData.length != defaultValue) {
        this.documentList = respData;
      }
    });
  }

  /**
  * get Events List
  */
  getEvents() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullEvents.params, (respData) => {
      if (respData.length != defaultValue) {
        this.configApiService.EventsList = respData;
        console.log(this.configApiService.EventsList, "EventsList")
        let supportGroup = this.configApiService.EventsList.filter(item => {
          const eventEndDate = new Date(item.Event_End_Date + 'T23:59:59');
          return item.Event_Type === this.currentEvent &&
            item.Event_Active === "true" &&
            eventEndDate >= this.currentDate;
        });
        this.supportGroup = supportGroup.sort((a, b) => a.Hierchy - b.Hierchy);
        console.log(this.supportGroup, "supportGroup")
        if (this.supportGroup.length > 0) {
          this.supportGroup.length < 2 ? this.disableSlideIcon = false : this.disableSlideIcon = true
          this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
          this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
          this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
          this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
          this.event_price = this.supportGroup[this.currentSlide].Event_Price;
          this.eventPrice = '₹ ' + this.event_price;
          this.event_Name = this.supportGroup[this.currentSlide].Event_Name
          this.showForm = true;
          if (this.event_price > defaultValue) {
            this.showPaymentScreen = true;
          }
        }
        else {
          this.showgif = true;
          this.showForm = false;
        }
      }
      else {
        this.showgif = true;
        this.showForm = false;
      }
      this.supportgroupEnable = true;
    });

  }

  /**
   * Support Group Form Submit
   */
  supportFormSubmit() {
    if (this.supportForm.valid && this.enableMobileError != true) {
      let postData = {
        "Support_Groups_Registation_1_Id": this.Support_Groups_Registation_1_Id || "0",
        "Event_Id": this.event_Id || 0,
        "Event_Name": this.event_Name || "",
        "Name": this.supportForm.controls.name.value || "",
        "Email": this.supportForm.controls.email.value || "",
        "Age": this.supportForm.controls.age.value || "",
        "Phone": this.supportForm.controls.phone.value || "",
        "Gender": this.supportForm.controls.gender.value || "",
        "Location": this.supportForm.controls.location.value || "",
        "Mode_of_Payment": "",
        "Group_Description": this.supportForm.controls.Group_Description.value || "",
        "Additional_Comments": this.supportForm.controls.Additional_Comments.value || "",
        "Any_Proir": "",
        "Therapy_before": "",
        "Events_Amount": this.event_price || 0,
        "Payment_Status": this.paymentStatus || "",
      }
      this.eitaDetails = postData;
      let objData = Object.assign({}, this.configApiService.lhcApi.postSupport_Groups.params, postData);
      this.configApiService.pushRequest(objData, this.resSupportSuccess)
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.supportForm);
    }
  }

  /**
   * Support Group Form Submit response
   */
  resSupportSuccess = ((resData) => {
    if (resData) {
      this.Registration_Id = resData['Support_Groups_Registation_15138'].Support_Groups_Registation_1_Id;
      if (this.event_price > defaultValue) {
        this.eventsPhonePePayment()
        // if (this.selectPayment == "paybyCard") {
        //   this.eventsCheckout();
        // }
        // if (this.selectPayment == "paybyUPI") {
        //   this.openUpiScreen();
        //   this.updateEitaTransaction();
        // }
      } else {
        this.configApiService.showEitaReg = true;
        this.sendEventSms();
        this.sendMail(this.eitaDetails.Email);
        this.openEitaReg();
      }
    }
  })

  /**
  * Group therapy Form Submit 
  */
  groupFormSubmit() {
    if (this.groupForm.valid && this.enableMobileError != true) {
      let postData = {
        "Group_Therapy_Registation_1_Id": this.Group_Therapy_Registation_1_Id || "0",
        "Event_Id": this.event_Id || 0,
        "Event_Name": this.event_Name || "",
        "Name": this.groupForm.controls.group_name.value || "",
        "Email": this.groupForm.controls.group_email.value || "",
        "Age": this.groupForm.controls.group_age.value || "",
        "Phone": this.groupForm.controls.group_phone.value || "",
        "Gender": this.groupForm.controls.group_gender.value || "",
        "Location": this.groupForm.controls.group_location.value || "",
        "Mode_of_Payment": this.selectPayment || "",
        "Group_Description": this.groupForm.controls.group_Group_Description.value || "",
        "Additional_Comments": this.groupForm.controls.group_Additional_Comments.value || "",
        "Any_Proir": this.groupForm.controls.group_Any_Proir.value || "",
        "Therapy_before": this.groupForm.controls.group_Therapy_before.value || "",
        "Events_Amount": this.event_price || 0,
        "Payment_Status": this.paymentStatus || "",
      }
      this.eitaDetails = postData;
      let objData = Object.assign({}, this.configApiService.lhcApi.postGroup_Therapy.params, postData);
      this.configApiService.pushRequest(objData, this.resGroupSuccess)
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.groupForm);
    }
  }

  /**
  * Group Therapy Form Submit response
  */
  resGroupSuccess = ((resData) => {
    if (resData) {
      this.Registration_Id = resData['Group_Therapy_Registation_15139'].Group_Therapy_Registation_1_Id
      //this.eventsCheckout();
      if (this.event_price > defaultValue) {
        this.eventsPhonePePayment()
        // if (this.selectPayment == "paybyCard") {
        //   this.eventsCheckout();
        // }
        // if (this.selectPayment == "paybyUPI") {
        //   this.openUpiScreen();
        //   this.updateEitaTransaction();
        // }
      } else {
        this.configApiService.showEitaReg = true;
        this.sendEventSms();
        this.sendMail(this.eitaDetails.Email);
        this.openEitaReg();
      }
    }
  })

  /**
  * Comfort Space Form Submit 
  */
  comfortFormSubmit() {
    if (this.comfortForm.valid && this.enableMobileError != true) {
      let postData = {
        "Comfort_Space_Registation_1_Id": this.comfortSpaceRegistation_1_Id || "0",
        "Event_Id": this.event_Id || 0,
        "Event_Name": this.event_Name || "",
        "Name": this.comfortForm.controls.comfort_name.value || "",
        "Email": this.comfortForm.controls.comfort_email.value || "",
        "Age": this.comfortForm.controls.comfort_age.value || 0,
        "Phone": this.comfortForm.controls.comfort_phone.value || 0,
        "Gender": this.comfortForm.controls.comfort_gender.value || "",
        "Location": this.comfortForm.controls.comfort_location.value || "",
        "Mode_of_Payment": "",
        "Group_Description": this.comfortForm.controls.comfort_Group_Description.value || "",
        "Additional_Comments": this.comfortForm.controls.comfort_Additional_Comments.value || "",
        "Events_Amount": this.event_price || 0,
        "Payment_Status": this.paymentStatus || "",
      }
      this.eitaDetails = postData;
      let objData = Object.assign({}, this.configApiService.lhcApi.postComfortSpace.params, postData);
      this.configApiService.pushRequest(objData, this.resSuccess)
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.comfortForm);
    }
  }

  /**
  * comfort Spaces Form Submit response
  */
  resSuccess = ((resData) => {
    if (resData) {
      this.Registration_Id = resData['Comfort_Space_Registation_15137'].Comfort_Space_Registation_1_Id;
      if (this.event_price > defaultValue) {
        this.eventsPhonePePayment()
        // if (this.selectPayment == "paybyCard") {
        //   this.eventsCheckout();
        // }
        // if (this.selectPayment == "paybyUPI") {
        //   this.openUpiScreen();
        //   this.updateEitaTransaction();
        // }
      }
      else {
        this.configApiService.showEitaReg = true;
        this.sendEventSms();
        this.sendMail(this.eitaDetails.Email);
        this.openEitaReg();
      }
    }
  })

  /**
  * Validation for form 
  * @param formGroup 
  */
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
  * Copy Function
  */
  copyInputMessage() {
    var textToCopy = document.getElementById("myInput").innerText.split(':')[1].trim();

    /* Create a temporary input element */
    var tempInput = document.createElement("input");

    /* Assign the text to be copied to the input element */
    tempInput.value = textToCopy;

    /* Append the input element to the document body */
    document.body.appendChild(tempInput);

    /* Select the text inside the input element */
    tempInput.select();

    /* Copy the selected text to the clipboard */
    document.execCommand("copy");

    /* Remove the temporary input element */
    document.body.removeChild(tempInput);

    /* Alert the user that the text has been copied */
    alert("Text copied to clipboard");
  }

  /***
  * method for Previous Slide 
  */
  previousSlide() {
    this.currentSlide--;
    if (this.currentSlide < 0) {
      this.currentSlide = this.supportGroup.length - 1;
      this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
      this.event_price = this.supportGroup[this.currentSlide].Event_Price;
      this.eventPrice = '₹ ' + this.event_price;
      this.event_Name = this.supportGroup[this.currentSlide].Event_Name
      this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
      this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
      this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
    }
    else {
      this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
      this.event_price = this.supportGroup[this.currentSlide].Event_Price;
      this.eventPrice = '₹ ' + this.event_price;
      this.event_Name = this.supportGroup[this.currentSlide].Event_Name
      this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
      this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
      this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
    }
    if (this.event_price > defaultValue) {
      this.showPaymentScreen = true;
    }
  }

  /***
  * method for Next Slide 
  */
  nextSlide() {
    this.currentSlide++;
    if (this.currentSlide >= this.supportGroup.length) {
      this.currentSlide = 0;
      this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
      this.event_price = this.supportGroup[this.currentSlide].Event_Price;
      this.eventPrice = '₹ ' + this.event_price;
      this.event_Name = this.supportGroup[this.currentSlide].Event_Name
      this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
      this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
      this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
    }
    else {
      this.event_Id = this.supportGroup[this.currentSlide].Event_Master_1_Id;
      this.event_price = this.supportGroup[this.currentSlide].Event_Price;
      this.eventPrice = '₹ ' + this.event_price;
      this.event_Name = this.supportGroup[this.currentSlide].Event_Name
      this.event_Time = this.supportGroup[this.currentSlide].Event_Time;
      this.whatsAppLink = this.supportGroup[this.currentSlide].Whats_App_Link;
      this.event_Date = this.supportGroup[this.currentSlide].Event_Date;
    }
    if (this.event_price > defaultValue) {
      this.showPaymentScreen = true;
    }
  }

  /**
  * get Transaction Summary
  */
  getTransaction() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pushLhc_Invoice.params, (respData) => {
      if (respData.length != defaultValue) {
        if (respData[0].LatestInvoiceNumber) {
          this.invoiceNo = respData[0].LatestInvoiceNumber;
        } else {
          this.invoiceNo = invoiceNumber;
        }
      }
    });
  }

  /**
   * Post Stripe Payment
   */
  eventsCheckout() {
    const customer = {
      Events_Id: this.event_Id || 0,
      Event_Name: this.event_Name || "",
      clientName: this.comfortForm.controls.comfort_name.value || this.groupForm.controls.group_name.value || this.supportForm.controls.name.value || "",
      Group_Type: this.currentEvent || "",
      Registration_Id: this.Registration_Id || 0,
      therapyPrice: this.event_price || 0,
      invoiceNumber: parseInt(this.invoiceNo) + 1,
      phnno: this.comfortForm.controls.comfort_phone.value || this.groupForm.controls.group_phone.value || this.supportForm.controls.phone.value || "",
      emailId: this.comfortForm.controls.comfort_email.value || this.supportForm.controls.email.value || this.groupForm.controls.group_email.value || '',
      therapyTypeName: this.event_Name || "",
      event_Date: this.event_Date,
      event_Time: this.event_Time,
      Address: this.locationMaster[0].Address,
      location: this.locationMaster[0].Location_Link,
      whatsAppLink: this.whatsAppLink,
      eitaDetails: this.eitaDetails,
      currentEvent: this.currentEvent,
    };
    localStorage.setItem('eventsDeatils', JSON.stringify(customer));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http.post(this.StripePaymentUrl, { customer }, { headers })
      .subscribe(
        (session: any) => {
          return this.stripe.redirectToCheckout({ sessionId: session.id })
            .then((result: any) => {
              if (result.error) {
                alert(result.error.message);
              }
            })
            .catch((error: any) => {
              console.error('Error:', error);
            });
        },
        (error: any) => {
          console.error('Error:', error);
        }
      );
  }

  /**
    * Post Phonepe Payment
    */
  eventsPhonePePayment() {
    const customer = {
      Events_Id: this.event_Id || 0,
      Event_Name: this.event_Name || "",
      clientName: this.comfortForm.controls.comfort_name.value || this.groupForm.controls.group_name.value || this.supportForm.controls.name.value || "",
      Group_Type: this.currentEvent || "",
      Registration_Id: this.Registration_Id || 0,
      therapyPrice: this.event_price || 0,
      invoiceNumber: parseInt(this.invoiceNo) + 1,
      phnno: this.comfortForm.controls.comfort_phone.value || this.groupForm.controls.group_phone.value || this.supportForm.controls.phone.value || "",
      emailId: this.comfortForm.controls.comfort_email.value || this.supportForm.controls.email.value || this.groupForm.controls.group_email.value || '',
      therapyTypeName: this.event_Name || "",
      event_Date: this.event_Date,
      event_Time: this.event_Time,
      Address: this.locationMaster[0].Address,
      location: this.locationMaster[0].Location_Link,
      whatsAppLink: this.whatsAppLink,
      eitaDetails: this.eitaDetails,
      currentEvent: this.currentEvent,
    };
    localStorage.setItem('eventsDeatils', JSON.stringify(customer));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http.post(this.phonePeUrl, { customer }, { headers })
      .subscribe(
        (checkoutUrl: any) => {
          this.checkoutUrl = checkoutUrl.URL;
          window.location.href = this.checkoutUrl;
          console.log(window.location.href)
        },
      );
  }

  /***
  * Open UPI popup screen  
  */
  openUpiScreen() {
    this.showUpiId = true;
  }

  /**
* Payment mode events
*/
  enablepayment(e) {
    this.selectPayment = e.target.value;
    console.log(this.selectPayment, "selectPayment")
    if (this.selectPayment == "paybyUPI") {
      this.openUpiScreen();
    } else {
      this.showUpiId = false;
    }
    this.checkPaymentmethod = true;
  }

  /**
* Update Eita Transaction
*/
  updateEitaTransaction() {
    this.updateInvoiceNo = parseInt(this.invoiceNo) + 1
    let postTransaction = {
      "Trans_Ref_id": "",
      "Events_Id": this.eitaDetails.Event_Id || 0,
      "Event_Name": this.eitaDetails.Event_Name || "",
      "Registration_Name": this.comfortForm.controls.comfort_name.value || this.groupForm.controls.group_name.value || this.supportForm.controls.name.value || "",
      "Group_Type": this.currentEvent || "",
      "Registration_Id": this.Registration_Id || 0,
      "Gw_Trans_Ref_id": 0,
      "Gw_trans_Status": 0,
      "Date_of_transacion": this.datePipe.transform(this.currentDate, this.dateFormat),
      "Amount_paid": this.eitaDetails.Events_Amount || 0,
      "Coupon": "",
      "Modeofpayment": "4",
      "Transaction_Status": "",
      "Invoice_no": this.updateInvoiceNo,
    }
    this.configApiService.etiaTransaction = postTransaction;
    let objData = Object.assign({}, this.configApiService.lhcApi.postEventTransaction.params, postTransaction);
    this.configApiService.pushRequest(objData, this.resTransactionSuccess)
  }

  /**
  * comfort Spaces Form Submit response
  */
  resTransactionSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
      this.configApiService.showEitaUpiSuccess = true;
      this.updateInvoice(this.updateInvoiceNo);
      this.sendEventSms();
      this.sendMail(this.eitaDetails.Email);
      this.openEitaSuccess();
    }
  })

  /***
 * Method for open Registration  success
 */
  openEitaSuccess(): void {
    var params = {
      eita: this.eitaDetails,
      invoiceNo: this.updateInvoiceNo,
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***
  * Method for open Registration  success
  */
  openEitaReg(): void {
    var params = {
      eita: this.eitaDetails,
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
  * Update Invoice number to master
  */
  updateInvoice(invoice) {
    let invoiceData = {
      LatestInvoiceNumber: invoice,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pullLhc_Invoice.params, invoiceData);
    this.configApiService.pushRequest(objData, this.resInovice)
  }

  /**
  * Invoice Response Data
  */
  resInovice = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Invoice Updated")
    }
  })

  /**
  * Send Mail api
  */
  // sendMail(mailId) {
  //   var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.eitaDetails.Name + "</h4><p> Thank you for registering for " + this.event_Name + ". Your payment is received and your registration has been confirmed. </p><p>We look forward to seeing you on,</p><br>Date:" + this.event_Date + " <br>Time:" + this.event_Time + "  <br>Location:<a href='" + this.locationMaster[0].Location_Link + "'>" + this.locationMaster[0].Address + "</a> <p>For any further queries, please reach out to us at +91 9677029999</p><div>Warm regards,<br>The Love Hope Company</div><br><br><p>Join our WhatsApp Community for more updates: <br><a href='" + this.whatsAppLink + "'>" + this.whatsAppLink + "</a> </p></body></html>"
  //   var mailSubject = "Registration Successful- The Love Hope Company!";
  //   var param = {
  //     TOID: mailId,
  //     CC: this.ccEmailId,
  //     BCC: "",
  //     FROMID: this.adminEmailId,
  //     SUBJECT: mailSubject,
  //     BODY: _mailhtmlbody,
  //   };
  //   var obj = {};
  //   obj['data'] = JSON.stringify(param);
  //   var pa = JSON.stringify(obj);
  //   this.http.post(this.sendUrl, pa).subscribe((response: any) => {
  //     console.log(response, "response")
  //   })
  // }

  /**
  * send mail through mail
  */
  sendMail(mailId) {
    let mailScheduler = {};
    var FROMID = this.adminEmailId;
    var TOID = mailId;
    var CC = this.adminEmailId;
    var BCC = this.ccEmailId
    var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.eitaDetails.Name + "</h4><p> Thank you for registering for " + this.event_Name + ". Your payment is received and your registration has been confirmed. </p><p>We look forward to seeing you on,</p><br>Date:" + this.event_Date + " <br>Time:" + this.event_Time + "  <br>Location:<a href='" + this.locationMaster[0].Location_Link + "'>" + this.locationMaster[0].Address + "</a> <p>For any further queries, please reach out to us at +91 9677029999</p><div>Warm regards,<br>The Love Hope Company</div><br><br><p>Join our WhatsApp Community for more updates: <br><a href='" + this.whatsAppLink + "'>" + this.whatsAppLink + "</a> </p></body></html>"
    var mailSubject = "Registration Successful- The Love Hope Company!";
    mailScheduler = { 'TOID': TOID, 'FROMID': FROMID, 'CC': CC, "BCC": BCC, 'BODY': _mailhtmlbody, 'ShareType': 1, 'SUBJECT': mailSubject };
    let objPushParams = Object.assign({}, this.configApiService.lhcApi.pushMailShareUser.params, mailScheduler);
    this.configApiService.pushRequest(objPushParams, this.mailSentResponseStatus);
  }

  mailSentResponseStatus = ((responsedata) => {
    if (responsedata == "SUCCESS") {
      console.log(responsedata, "responsedata")
    }
  });


  /**
  * Send Events SMS api
  */
  sendEventSms() {
    var param = {
      TOID: this.eitaDetails.Phone,
      Event_Name: this.eitaDetails.Event_Name,
      Event_Date: this.event_Date,
    };
    var obj = {};
    obj['data'] = JSON.stringify(param);
    var pa = JSON.stringify(obj);
    this.http.post(this.sendEventSMSUrl, pa).subscribe((response: any) => {
      console.log(response, "response")
    })
  }

  /**
   * Submit contact Form
   */
  getLocation() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullLocationMaster.params, (respData) => {
      if (respData.length != defaultValue) {
        this.locationMaster = respData;
      }
    });
  }

  /**
   * Check mobile already registered
   */
  checkMobile(e) {
    if (e.target.value) {
      var params = {
        Phone: e.target.value
      }
      if (this.currentEvent == 'SupportGroup') {
        let objData = Object.assign({}, this.configApiService.lhcApi.pullSupportRegistration.params, params);
        this.configApiService.pullRequest(objData, (respData) => {
          if (respData.length != defaultValue) {
            /**
             * Check Event and unpaid payment status 
             */
            let duplicateNumber = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "UnPaid"
            })

            /**
             * Check Event and suucess payment status 
             */
            let duplicateNumberPaid = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "PAYMENT_SUCCESS" || e.Payment_Status == ""
            })
            if (duplicateNumber.length > 0) {
              this.enableMobileError = true;
              let title = "Event"
              let message = "This Number already Registered are you Retry the payment ?"
              this.openAlert(message, title, (result) => {
                if (result == "yes") {
                  this.enableMobileError = false;
                  this.Support_Groups_Registation_1_Id = duplicateNumber[defaultValue].Support_Groups_Registation_1_Id;
                  this.event_Id = duplicateNumber[defaultValue].Event_Id;
                  this.event_Name = duplicateNumber[defaultValue].Event_Name;
                  this.name = duplicateNumber[defaultValue].Name;
                  this.email = duplicateNumber[defaultValue].Email;
                  this.age = duplicateNumber[defaultValue].Age;
                  this.phone = duplicateNumber[defaultValue].Phone;
                  this.gender = duplicateNumber[defaultValue].Gender;
                  this.location = duplicateNumber[defaultValue].Location;
                  this.modeOfPayment = duplicateNumber[defaultValue].Mode_of_Payment;
                  this.groupDescription = duplicateNumber[defaultValue].Group_Description;
                  this.additionalComments = duplicateNumber[defaultValue].Additional_Comments;
                  this.eventPrice = duplicateNumber[defaultValue].Events_Amount;
                  this.paymentStatus = duplicateNumber[defaultValue].Payment_Status;
                }
              })
            } else {
              this.enableMobileError = false;
            }
            if (duplicateNumberPaid.length > defaultValue) {
              this.enableMobileError = true;
              let title = "Alert"
              let message = "This Number already Registered"
              this.openAlert(message, title, (result) => {
              })
            }
          } else {
            this.enableMobileError = false;
          }
        });
      } else if (this.currentEvent == 'ComfortSpaces') {
        let objData = Object.assign({}, this.configApiService.lhcApi.pullComfortSpaceregistration.params, params);
        this.configApiService.pullRequest(objData, (respData) => {
          if (respData.length != defaultValue) {
            /**
             * Check Event and unpaid payment status 
             */
            let duplicateNumber = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "UnPaid"
            })

            /**
             * Check Event and suucess payment status 
             */
            let duplicateNumberPaid = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "PAYMENT_SUCCESS" || e.Payment_Status == ""
            })
            if (duplicateNumber.length > defaultValue) {
              this.enableMobileError = true;
              let title = "Event"
              let message = "This Number already Registered are you Retry the payment ?"
              this.openAlert(message, title, (result) => {
                if (result == "yes") {
                  this.enableMobileError = false;
                  this.comfortSpaceRegistation_1_Id = duplicateNumber[defaultValue].Comfort_Space_Registation_1_Id;
                  this.event_Id = duplicateNumber[defaultValue].Event_Id;
                  this.event_Name = duplicateNumber[defaultValue].Event_Name;
                  this.name = duplicateNumber[defaultValue].Name;
                  this.email = duplicateNumber[defaultValue].Email;
                  this.age = duplicateNumber[defaultValue].Age;
                  this.phone = duplicateNumber[defaultValue].Phone;
                  this.gender = duplicateNumber[defaultValue].Gender;
                  this.location = duplicateNumber[defaultValue].Location;
                  this.modeOfPayment = duplicateNumber[defaultValue].Mode_of_Payment;
                  this.groupDescription = duplicateNumber[defaultValue].Group_Description;
                  this.additionalComments = duplicateNumber[defaultValue].Additional_Comments;
                  this.eventPrice = duplicateNumber[defaultValue].Events_Amount;
                  this.paymentStatus = duplicateNumber[defaultValue].Payment_Status;
                }
              })
            } else {
              this.enableMobileError = false;
            }
            if (duplicateNumberPaid.length > defaultValue) {
              this.enableMobileError = true;
              let title = "Alert"
              let message = "This Number already Registered"
              this.openAlert(message, title, (result) => {
              })
            }
          } else {
            this.enableMobileError = false;
          }
        });
      } else {
        let objData = Object.assign({}, this.configApiService.lhcApi.pullGroupRegistration.params, params);
        this.configApiService.pullRequest(objData, (respData) => {
          if (respData.length != defaultValue) {

            /**
             * Check Event and unpaid payment status 
             */
            let duplicateNumber = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "UnPaid"
            })

            /**
             * Check Event and suucess payment status 
             */
            let duplicateNumberPaid = respData.filter(e => {
              return e.Event_Id == this.event_Id && e.Payment_Status == "PAYMENT_SUCCESS" || e.Payment_Status == ""
            })
            
            if (duplicateNumber.length > 0) {
              this.enableMobileError = true;
              let title = "Event"
              let message = "This Number already Registered are you Retry the payment ?"
              this.openAlert(message, title, (result) => {
                if (result == "yes") {
                  this.enableMobileError = false;
                  this.Group_Therapy_Registation_1_Id = duplicateNumber[defaultValue].Group_Therapy_Registation_1_Id;
                  this.event_Id = duplicateNumber[defaultValue].Event_Id;
                  this.event_Name = duplicateNumber[defaultValue].Event_Name;
                  this.name = duplicateNumber[defaultValue].Name;
                  this.email = duplicateNumber[defaultValue].Email;
                  this.age = duplicateNumber[defaultValue].Age;
                  this.phone = duplicateNumber[defaultValue].Phone;
                  this.gender = duplicateNumber[defaultValue].Gender;
                  this.location = duplicateNumber[defaultValue].Location;
                  this.modeOfPayment = duplicateNumber[defaultValue].Mode_of_Payment;
                  this.groupDescription = duplicateNumber[defaultValue].Group_Description;
                  this.additionalComments = duplicateNumber[defaultValue].Additional_Comments;
                  this.eventPrice = duplicateNumber[defaultValue].Events_Amount;
                  this.paymentStatus = duplicateNumber[defaultValue].Payment_Status;
                  this.anyProir = duplicateNumber[defaultValue].Any_Proir;
                  this.therapyBefore = duplicateNumber[defaultValue].Therapy_before;
                }
              })
            } else {
              this.enableMobileError = false;
            }
            if (duplicateNumberPaid.length > defaultValue) {
              this.enableMobileError = true;
              let title = "Alert"
              let message = "This Number already Registered"
              this.openAlert(message, title, (result) => {
              })
            }
          } else {
            this.enableMobileError = false;
          }
        });
      }
    }
  }

}
