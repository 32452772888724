import { Component, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigApiServiceService } from './providers/config-api-service.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SuccessPaymentComponent } from './success-payment/success-payment/success-payment.component';
import { toWords } from 'number-to-words';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'epc-lhc';
  sessionID: any;
  transactionId: any;
  phonePeDetails: any;
  dateFormat = 'yyyy-MM-dd';
  currentDate = new Date();
  showSuccess: boolean = false;
  customer: any;
  adminEmailId: any = "hello@thelovehopecompany.com";
  //paymentUrl: any = 'https://localhost:3784/getPaymentId'; // Local_Url
  paymentUrl: any = 'https://drive.prodcast.cloud:3784/getPaymentId'; // Live_Url
  sendUrl: any = 'https://drive.prodcast.cloud:3784/sendMail'; // Live_Url
  //sendUrl: any = 'https://localhost:3784/sendMail'; // Local_Url
  //sendEventSMSUrl: any = 'https://localhost:3784/sendEventSMS'; // Local_Url'
  sendEventSMSUrl: any = 'https://drive.prodcast.cloud:3784/sendEventSMS'; // Live_Url'
  paymentDetail: any;
  convertRupees: any;
  eitaData: any;
  session: any;
  sesstionId: any;
  therapyPriceWord: any;
  serviceType: string;
  eitaMail: boolean = false;
  ccEmailId: any = "admin@thelovehopecompany.com"
  paymentStatus: any = "Payment Successfull";
  phonePeUrl: any = 'https://drive.prodcast.cloud:3784/getPhonePeTransaction'; // Live_Url
  //phonePeUrl: any = 'https://localhost:3784/getPhonePeTransaction'; // Local_Url

  constructor(public dialog: MatDialog, private router: Router, private http: HttpClient, public configApiService: ConfigApiServiceService, public datePipe: DatePipe,) { }

  ngOnInit() {
    this.getQueryParamValues();
  }

  getQueryParamValues() {
    if (window.location.hash) {
      let query = window.location.hash.split('?');
      if (query && query.length > 1) {
        let queryParams = query[1].split('&');
        for (var i = 0; i < queryParams.length; i++) {
          let searchUrl = queryParams[i].split("=");
          // if (searchUrl[0].toLocaleLowerCase() == 'sessionid') {
          //   this.sessionID = searchUrl[1];
          //   this.getPaymentDetail(this.sessionID);
          // }
          if (searchUrl[0].toLocaleLowerCase() == 'transactionid') {
            this.transactionId = searchUrl[1].split('%')[0];
            this.getPhonePeStatus(this.transactionId)
            console.log(this.transactionId)
          }
        }
      }
    }
  }

  /**
   * Get Payment Details from Stripe 
   */
  async getPhonePeStatus(transactionId) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http.post(this.phonePeUrl, { transactionId }, { headers })
      .subscribe(
        (phonePeStatus: any) => {
          console.log(phonePeStatus, "phonePeStatus")
          this.phonePeDetails = phonePeStatus;
          if (localStorage.getItem('CustomerDeatils') != null && localStorage.getItem('CustomerDeatils') != 'undefined') {
            this.customer = localStorage.getItem('CustomerDeatils');
            this.customer = JSON.parse(localStorage.getItem('CustomerDeatils'))
            console.log(this.customer, "customer")
            this.configApiService.mailToClientReg = true;
            this.updateCustomerMaster(this.customer);
          }
          if (localStorage.getItem('eitaDeatils') != null && localStorage.getItem('eitaDeatils') != 'undefined') {
            this.eitaData = localStorage.getItem('eitaDeatils');
            this.eitaData = JSON.parse(localStorage.getItem('eitaDeatils'))
            console.log(this.eitaData, "Eita")
            this.eitaMail = true;
            this.configApiService.mailToClientReg = false;
            this.updateEitaTransaction(this.phonePeDetails, this.eitaData)
          }
          if (localStorage.getItem('eventsDeatils') != null && localStorage.getItem('eventsDeatils') != 'undefined') {
            this.eitaData = localStorage.getItem('eventsDeatils');
            this.eitaData = JSON.parse(localStorage.getItem('eventsDeatils'))
            console.log(this.eitaData, "eventsDeatils")
            this.configApiService.mailToClientReg = false;
            this.updateEitaTransaction(this.phonePeDetails, this.eitaData)
          }
          if (localStorage.getItem('sessionDeatils') != null && localStorage.getItem('sessionDeatils') != 'undefined') {
            this.session = localStorage.getItem('sessionDeatils');
            this.session = JSON.parse(localStorage.getItem('sessionDeatils'))
            console.log(this.session, "sessionDeatils")
            this.sessionMaster(this.session)
          }
        },
        (error: any) => {
          console.error('Error:', error);
        }
      );
  }

  /**
   * Get Payment Details from Stripe 
   */
  // async getPaymentDetail(sessionID) {
  //   // const session = await this.stripeServ.getStripeInstance().checkout.sessions.retrieve(sessionID);
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   this.http.post(this.paymentUrl, { sessionID }, { headers })
  //     .subscribe(
  //       (payment: any) => {
  //         this.paymentDetail = payment;
  //         if (localStorage.getItem('CustomerDeatils') != null && localStorage.getItem('CustomerDeatils') != 'undefined') {
  //           this.customer = localStorage.getItem('CustomerDeatils');
  //           this.customer = JSON.parse(localStorage.getItem('CustomerDeatils'))
  //           console.log(this.customer, "customer")
  //           this.configApiService.mailToClientReg = true;
  //         }
  //         if (localStorage.getItem('eitaDeatils') != null && localStorage.getItem('eitaDeatils') != 'undefined') {
  //           this.eitaData = localStorage.getItem('eitaDeatils');
  //           this.eitaData = JSON.parse(localStorage.getItem('eitaDeatils'))
  //           console.log(this.eitaData, "Eita")
  //           this.configApiService.mailToClientReg = false;
  //           this.updateEitaTransaction(payment, this.eitaData)
  //         }
  //         if (localStorage.getItem('eventsDeatils') != null && localStorage.getItem('eventsDeatils') != 'undefined') {
  //           this.eitaData = localStorage.getItem('eventsDeatils');
  //           this.eitaData = JSON.parse(localStorage.getItem('eventsDeatils'))
  //           console.log(this.eitaData, "eventsDeatils")
  //           this.eitaMail = true;
  //           this.configApiService.mailToClientReg = false;
  //           this.updateEitaTransaction(payment, this.eitaData)
  //         }
  //         if (localStorage.getItem('sessionDeatils') != null && localStorage.getItem('sessionDeatils') != 'undefined') {
  //           this.session = localStorage.getItem('sessionDeatils');
  //           this.session = JSON.parse(localStorage.getItem('sessionDeatils'))
  //           console.log(this.session, "sessionDeatils")
  //           this.sessionMaster(this.session)
  //         }
  //       },
  //       (error: any) => {
  //         console.error('Error:', error);
  //       }
  //     );
  // }

  /**
   * Create Session Master  
   */
  sessionMaster(session) {
    console.log(session, "session")
    var postData = {
      "Patient_No": session.Patient_No,
      "Patient_Name": session.Patient_Name,
      "Req_Day": session.Req_Day,
      "Req_Slot": session.Req_Slot,
      "Therapy_type": session.Therapy_type,
      "Initial_Payment": session.Initial_Payment,
      "Mode_of_Therapy": session.Mode_of_Therapy,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pushsession_master.params, postData);
    this.configApiService.pushRequest(objData, this.resSession)
  }

  /**
   * Get session Id
   */
  resSession = ((resData) => {
    if (resData) {
      this.sesstionId = resData.session_master5078.session_master_Id
      this.updateTransaction(this.sesstionId);
    }
  })


  /**
   * Post Registration Transaction Data
   */
  updateTransaction(sessionId) {
    this.convertRupees = this.phonePeDetails.data.amount / 100
    var postData = {
      Amount_paid: this.convertRupees,
      Date_of_transacion: this.datePipe.transform(this.currentDate, this.dateFormat),
      Gw_Trans_Ref_id: this.phonePeDetails.data.paymentInstrument.pgTransactionId || this.phonePeDetails.data.paymentInstrument.utr || "",
      Gw_trans_Status: this.phonePeDetails.data.responseCode || "",
      Modeofpayment: "1",
      Invoice_no: this.customer.invoiceNumber || '',
      Patient_No: this.customer.clientId || '',
      Patient_Name: this.customer.clientName || '',
      Coupon: this.customer.couponCode || '',
      Trans_Ref_id: this.phonePeDetails.data.transactionId || 0,
      session_id: sessionId || 0,
      Trans_Email_status: this.phonePeDetails.data.responseCode || "",
      Trans_Email_date_and_time: this.datePipe.transform(this.currentDate, this.dateFormat),
    }
    this.configApiService.transaction = postData;
    let objData = Object.assign({}, this.configApiService.lhcApi.Loho_Transaction.params, postData);
    this.configApiService.pushRequest(objData, this.resTransaction)
  }

  /**
   * Get Transaction Status
   */
  resTransaction = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
      this.updateInvoice(this.customer.invoiceNumber);
      this.sendMail(this.customer.emailId);
      this.submitInvoiceData();
      this.openRegSuccess();
    }
  })

  /**
  * Update Eita Transaction
  */
  updateEitaTransaction(payment, eita) {
    this.paymentDetail = payment
    this.convertRupees = payment.amount / 100 || this.phonePeDetails.data.amount / 100
    let postTransaction = {
      "Trans_Ref_id": payment.id || this.phonePeDetails.data.transactionId,
      "Events_Id": eita.Events_Id || 0,
      "Event_Name": eita.Event_Name || "",
      "Registration_Name": eita.clientName || "",
      "Group_Type": eita.Group_Type || "",
      "Registration_Id": eita.Registration_Id || 0,
      "Gw_Trans_Ref_id": payment.payment_method || this.phonePeDetails.data.paymentInstrument.pgTransactionId || this.phonePeDetails.data.paymentInstrument.utr || "",
      "Gw_trans_Status": payment.object || this.phonePeDetails.data.responseCode || "",
      "Date_of_transacion": this.datePipe.transform(this.currentDate, this.dateFormat),
      "Amount_paid": this.convertRupees || 0,
      "Coupon": "",
      "Modeofpayment": this.phonePeDetails.data.paymentInstrument.type || "",
      "Transaction_Status": payment.status || this.phonePeDetails.data.state,
      "Invoice_no": eita.invoiceNumber,
    }
    this.configApiService.etiaTransaction = postTransaction;
    let objData = Object.assign({}, this.configApiService.lhcApi.postEventTransaction.params, postTransaction);
    this.configApiService.pushRequest(objData, this.resTransactionSuccess)
  }

  /**
  * comfort Spaces Form Submit response
  */
  resTransactionSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
      this.updateInvoice(this.eitaData.invoiceNumber);
      this.sendEventSms();
      this.sendMail(this.eitaData.emailId);
      this.submitEitaInvoiceData()
      this.openEitaSuccess();
      if (this.eitaData.currentEvent == 'Eita') {
        this.paymentEitaStatusUpdate();
      }
      if (this.eitaData.currentEvent == 'SupportGroup') {
        this.paymentSupportGroupStatusUpdate();
      }
      if (this.eitaData.currentEvent == 'GroupTherapy') {
        this.paymentGroupTherapyStatusUpdate();
      }
      if (this.eitaData.currentEvent == 'ComfortSpaces') {
        this.paymentComfortSpacesStatusUpdate();
      }
    }
  })

  /***
   * Method for open Registration  success
   */
  openRegSuccess(): void {
    var params = {
      customer: this.customer,
      payment: this.configApiService.transaction,
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***
    * Method for save invoice data
    */
  submitInvoiceData(): void {
    this.therapyPriceWord = toWords(this.customer.therapyPrice);
    var postData = {
      "Client_Name_": this.customer.clientName,
      "Email_id_": this.customer.emailId,
      "Phone_Number": this.customer.phnno,
      "Invoice_Number": this.customer.invoiceNumber,
      "Invoice_Date": this.datePipe.transform(this.currentDate, this.dateFormat),
      "Service_Type": this.customer.therapyTypeName,
      "Initial_amount_": this.customer.therapyPrice,
      "Additional_amount_": "",
      "Total_amount_": this.customer.therapyPrice,
      "Amount_in_words": this.therapyPriceWord,
      "Mode_of_payment_": this.phonePeDetails.data.paymentInstrument.type || "",
      "Payment_Ref": this.configApiService.transaction.Trans_Ref_id,
      "Hours": "",
      "Additional_Hours_": "",
      "Description_1": "",
      "Description_2": "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pushInvoiceData.params, postData);
    this.configApiService.pushRequest(objData, this.resInvoiceDataSuccess)
  }

  /**
  * Invoice Data Form Submit response
  */
  resInvoiceDataSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
    }
  })

  /***
  * Method for save Eita invoice data
  */
  submitEitaInvoiceData(): void {
    this.therapyPriceWord = toWords(this.configApiService.etiaTransaction.Amount_paid);
    if (this.eitaData.no_Of_child && this.eitaData.eventPrice) {
      this.serviceType = this.eitaData.Event_Name + "(child " + this.eitaData.no_Of_child + "*" + this.eitaData.eventPrice + ")"
    }
    else {
      this.serviceType = this.eitaData.Event_Name
    }
    var postData = {
      "Client_Name_": this.eitaData.clientName,
      "Email_id_": this.eitaData.emailId,
      "Phone_Number": this.eitaData.phnno,
      "Invoice_Number": this.eitaData.invoiceNumber,
      "Invoice_Date": this.datePipe.transform(this.currentDate, this.dateFormat),
      "Service_Type": this.serviceType,
      "Initial_amount_": this.configApiService.etiaTransaction.Amount_paid,
      "Additional_amount_": "",
      "Total_amount_": this.configApiService.etiaTransaction.Amount_paid,
      "Amount_in_words": this.therapyPriceWord,
      "Mode_of_payment_": this.phonePeDetails.data.paymentInstrument.type || "",
      "Payment_Ref": this.configApiService.etiaTransaction.Trans_Ref_id,
      "Hours": "",
      "Additional_Hours_": "",
      "Description_1": "",
      "Description_2": "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pushInvoiceData.params, postData);
    this.configApiService.pushRequest(objData, this.resEitaInvoiceDataSuccess)
  }

  /***
  * Invoice Data Form Submit response
  */
  resEitaInvoiceDataSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Success")
    }
  })

  /***
   * Method for open Registration  success
   */
  openEitaSuccess(): void {
    var params = {
      eita: this.eitaData,
      payment: this.configApiService.etiaTransaction
    }
    const dialogRef = this.dialog.open(SuccessPaymentComponent, {
      data: { params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
  * Update Invoice number to master
  */
  updateInvoice(invoice) {
    let invoiceData = {
      LatestInvoiceNumber: invoice,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pullLhc_Invoice.params, invoiceData);
    this.configApiService.pushRequest(objData, this.resInovice)
  }

  /**
  * Invoice Response Data
  */
  resInovice = ((resData) => {
    if (resData != 'FAIL') {
      console.log("Invoice Updated")
    }
  })

  /**
  * Update payment status to Eita Registration
  */
  paymentEitaStatusUpdate() {
    if (this.eitaData.Registration_Id.length > 0) {
      this.eitaData.Registration_Id.forEach((e: any) => {
        var params = {
          Event_Registration_Id: e
        }
        let objData = Object.assign({}, this.configApiService.lhcApi.pullEitaRegistration.params, params);
        this.configApiService.pullRequest(objData, (respData) => {
          if (respData.length > 0) {
            let postData = {
              "Event_Registration_Id": respData[0].Event_Registration_Id || 0,
              "Event_Name": respData[0].Event_Name || "",
              "Name": respData[0].Name || "",
              "Age": respData[0].Age || "",
              "Grade": respData[0].Grade || "",
              "School": respData[0].School || "",
              "ParentName_one": respData[0].ParentName_one || "",
              "ParentName_two": "",
              "Mobile_no": respData[0].Mobile_no || "",
              "Email": respData[0].Email || "",
              "Referral_eita": respData[0].Referral_eita || "",
              "Description": respData[0].Description || "",
              "Additional_comments": respData[0].Additional_comments || "",
              "Event_id": respData[0].Event_id || 0,
              "Event_amount": respData[0].Event_amount || 0,
              "Mode_of_Payment": this.phonePeDetails.data.paymentInstrument.type || "",
              "Payment_Status": this.phonePeDetails.code || "",
            }
            let objData = Object.assign({}, this.configApiService.lhcApi.postEitaRegistration.params, postData);
            this.configApiService.pushRequest(objData, this.resSuccess)
          }
        })
      })
    }
  }

  /**
  * Group Therapy Form Submit response
  **/
  resSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log(resData)
      let Registration_Id = resData['Event_Registration5146'].Event_Registration_Id
      console.log(Registration_Id, "** Registration_Id **")
    }
  })

  /**
  * Update payment status to Support Group Registration
  */
  paymentSupportGroupStatusUpdate() {
    let postData = {
      "Support_Groups_Registation_1_Id": this.eitaData.Registration_Id || 0,
      "Event_Id": this.eitaData.eitaDetails.Event_Id || 0,
      "Event_Name": this.eitaData.eitaDetails.Event_Name || "",
      "Name": this.eitaData.eitaDetails.Name || "",
      "Email": this.eitaData.eitaDetails.Email || "",
      "Age": this.eitaData.eitaDetails.Age || "",
      "Phone": this.eitaData.eitaDetails.Phone || "",
      "Gender": this.eitaData.eitaDetails.Gender || "",
      "Location": this.eitaData.eitaDetails.Location || "",
      "Mode_of_Payment": this.phonePeDetails.data.paymentInstrument.type || "",
      "Group_Description": this.eitaData.eitaDetails.Group_Description || "",
      "Additional_Comments": this.eitaData.eitaDetails.Additional_Comments || "",
      "Any_Proir": "",
      "Therapy_before": "",
      "Events_Amount": this.eitaData.eitaDetails.Events_Amount || 0,
      "Payment_Status": this.phonePeDetails.code || "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.postSupport_Groups.params, postData);
    this.configApiService.pushRequest(objData, this.resSupportSuccess)
  }

  /**
   * Support Group Form Submit response
   */
  resSupportSuccess = ((resData) => {
    if (resData != 'FAIL') {
      var Registration_Id = resData['Support_Groups_Registation_15138'].Support_Groups_Registation_1_Id;
      console.log(Registration_Id, "** Registration_Id **")
    }
  })

  /**
  * Update payment status to Group Therapy Registration
  */
  paymentGroupTherapyStatusUpdate() {
    let postData = {
      "Group_Therapy_Registation_1_Id": this.eitaData.Registration_Id || 0,
      "Event_Id": this.eitaData.eitaDetails.Event_Id || 0,
      "Event_Name": this.eitaData.eitaDetails.Event_Name || "",
      "Name": this.eitaData.eitaDetails.Name || "",
      "Email": this.eitaData.eitaDetails.Email || "",
      "Age": this.eitaData.eitaDetails.Age || "",
      "Phone": this.eitaData.eitaDetails.Phone || "",
      "Gender": this.eitaData.eitaDetails.Gender || "",
      "Location": this.eitaData.eitaDetails.Location || "",
      "Mode_of_Payment": this.phonePeDetails.data.paymentInstrument.type || "",
      "Group_Description": this.eitaData.eitaDetails.Group_Description || "",
      "Additional_Comments": this.eitaData.eitaDetails.Additional_Comments || "",
      "Any_Proir": this.eitaData.eitaDetails.Any_Proir || "",
      "Therapy_before": this.eitaData.eitaDetails.Therapy_before || "",
      "Events_Amount": this.eitaData.eitaDetails.Events_Amount || 0,
      "Payment_Status": this.phonePeDetails.code || "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.postGroup_Therapy.params, postData);
    this.configApiService.pushRequest(objData, this.resGroupSuccess)
  }

  /**
 * Support Group Form Submit response
 */
  resGroupSuccess = ((resData) => {
    if (resData != 'FAIL') {
      var Registration_Id = resData['Group_Therapy_Registation_15139'].Support_Groups_Registation_1_Id;
      console.log(Registration_Id, "** Registration_Id **")
    }
  })

  /**
  * Update payment status to Comfort Spaces Registration
  */
  paymentComfortSpacesStatusUpdate() {
    let postData = {
      "Comfort_Space_Registation_1_Id": this.eitaData.Registration_Id || 0,
      "Event_Id": this.eitaData.eitaDetails.Event_Id || 0,
      "Event_Name": this.eitaData.eitaDetails.Event_Name || "",
      "Name": this.eitaData.eitaDetails.Name || "",
      "Email": this.eitaData.eitaDetails.Email || "",
      "Age": this.eitaData.eitaDetails.Age || "",
      "Phone": this.eitaData.eitaDetails.Phone || "",
      "Gender": this.eitaData.eitaDetails.Gender || "",
      "Location": this.eitaData.eitaDetails.Location || "",
      "Mode_of_Payment": this.phonePeDetails.data.paymentInstrument.type || "",
      "Group_Description": this.eitaData.eitaDetails.Group_Description || "",
      "Additional_Comments": this.eitaData.eitaDetails.Additional_Comments || "",
      "Events_Amount": this.eitaData.eitaDetails.Events_Amount || 0,
      "Payment_Status": this.phonePeDetails.code || "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.postComfortSpace.params, postData);
    this.configApiService.pushRequest(objData, this.resCSSuccess)
  }

  /**
  * comfort Spaces Form Submit response
  */
  resCSSuccess = ((resData) => {
    if (resData != 'FAIL') {
      var Registration_Id = resData['Comfort_Space_Registation_15137'].Comfort_Space_Registation_1_Id;
      console.log(Registration_Id, "** Registration_Id **")
    }
  })

  /**
  * send mail through mail
  */
  sendMail(mailId) {
    let mailScheduler = {};
    var FROMID = this.adminEmailId;
    var TOID = mailId;
    var CC = this.adminEmailId;
    var BCC = this.ccEmailId
    if (this.configApiService.mailToClientReg && !this.eitaMail) {
      var mailSubject = "Registration Successful- Book A Couch!";
      var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.customer.clientName + "</h4>" + "<p>Welcome to The Love Hope Company!</p> <p>Your registration for Book a Couch has been successfully processed.</p><table style='width:50%;border: 1px solid black'><tr><th style='border: 1px solid black'>Name</th><th style='border: 1px solid black'>" + this.customer.clientName + "</th></tr><tr><th style='border: 1px solid black'>Order Id</th><th style='border: 1px solid black'> " + this.transactionId + "</th></tr><tr><th style='border: 1px solid black'>Payment Id</th><th style='border: 1px solid black'> " + this.phonePeDetails.data.transactionId + "</th></tr><tr><th style='border: 1px solid black'>Invoice No</th><th style='border: 1px solid black'>" + this.customer.invoiceNumber + "</th></tr><tr><th style='border: 1px solid black'>Amount</th><th style='border: 1px solid black'>" + this.convertRupees + "</th></tr></table><p> Within 24-48 working hours, our team will contact you to match you with a certified professional who best aligns with your specific needs and preferences.</p><p>If you have any immediate questions or concerns, please don't hesitate to drop a text at +91 9677029999. We are here to assist you every step of the way.</p><div>With Love and Hope,<br>Team TLHC</div><img style='width:20%' src='https://drive.prodcast.cloud:3784/uploads/Layer394.jpg'></body></html>"
    }
    if (!this.configApiService.mailToClientReg && !this.eitaMail) {
      var mailSubject = "Registration Successful- The Love Hope Company!";
      var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.eitaData.clientName + "</h4><p> Thank you for registering for " + this.eitaData.Event_Name + ". Your payment is received and your registration has been confirmed. </p><p>We look forward to seeing you on,</p><br>Date : " + this.eitaData.event_Date + " <br>Time : " + this.eitaData.event_Time + "  <br>Location : <a href='" + this.eitaData.location + "'>" + this.eitaData.Address + "</a> <p>For any further queries, please reach out to us at +91 9677029999</p><div>Warm regards,<br>The Love Hope Company</div><br><br><p>Join our WhatsApp Community for more updates: <br><a href='" + this.eitaData.whatsAppLink + "'>" + this.eitaData.whatsAppLink + "</a> </p></body></html>"
    }
    if (!this.configApiService.mailToClientReg && this.eitaMail) {
      var mailSubject = "Registration Successful- The Love Hope Company!";
      var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.eitaData.ParentName + "</h4><p> Thank you for registering for " + this.eitaData.Event_Name + ". Your payment is received and your registration has been confirmed. </p><p>We look forward to seeing " + this.eitaData.childName + " on,</p><br>Date : " + this.eitaData.event_Date + " <br>Time : " + this.eitaData.event_Time + "  <br>Location : <a href='" + this.eitaData.location + "'>" + this.eitaData.Address + "</a> <p>For any further queries, please reach out to us at +91 9677029999</p><div>Warm regards,<br>The Love Hope Company</div><br><br><p>Join our WhatsApp Community for more updates: <br><a href='" + this.eitaData.whatsAppLink + "'>" + this.eitaData.whatsAppLink + "</a> </p></body></html>"
    }
    mailScheduler = { 'TOID': TOID, 'FROMID': FROMID, 'CC': CC, "BCC": BCC, 'BODY': _mailhtmlbody, 'ShareType': 1, 'SUBJECT': mailSubject };
    let objPushParams = Object.assign({}, this.configApiService.lhcApi.pushMailShareUser.params, mailScheduler);
    this.configApiService.pushRequest(objPushParams, this.mailSentResponseStatus);
  }

  mailSentResponseStatus = ((responsedata) => {
    if (responsedata == "SUCCESS") {
      console.log(responsedata, "responsedata")
    }
  });

  /**
  * Send Events SMS api
  */
  sendEventSms() {
    var param = {
      TOID: this.eitaData.phnno,
      Event_Name: this.eitaData.Event_Name,
      Event_Date: this.eitaData.event_Date,
    };
    var obj = {};
    obj['data'] = JSON.stringify(param);
    var pa = JSON.stringify(obj);
    this.http.post(this.sendEventSMSUrl, pa).subscribe((response: any) => {
      console.log(response, "response")
    })
  }

  /**
  * Update customer master payment status 
  */
  updateCustomerMaster(customer) {
    var postData = {
      Current_Location: customer.customerDetails.Current_Location || '',
      Cus_DOB: customer.customerDetails.Cus_DOB || '',
      Cus_Name: customer.customerDetails.Cus_Name || '',
      Email_id: customer.customerDetails.Email_id || '',
      Emergency_contact_no: customer.customerDetails.Emergency_contact_no || '',
      Gender: customer.customerDetails.Gender || '',
      Language_preference: customer.customerDetails.Language_preference || 0,
      Mobile_no: customer.customerDetails.Mobile_no || 0,
      Occupation: customer.customerDetails.Occupation || 0,
      Reason_for_seeking_help: customer.customerDetails.Reason_for_seeking_help || '',
      Source_of_referral: customer.customerDetails.Source_of_referral || 0,
      customer_Master_Id: customer.customerId || '',
      Patient_no: customer.clientId || '',
      Payment_Status: this.phonePeDetails.code || "",
      Mode_of_Payment: this.phonePeDetails.data.paymentInstrument.type || "",
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pushLoho_Customer_Master.params, postData);
    this.configApiService.pushRequest(objData, this.updateCustomerResSuccess)
  }

  /**
  * Update customer master Result
  */
  updateCustomerResSuccess = ((resData) => {
    if (resData != 'FAIL') {
      console.log(" Update Customer Details ")
    }
  })
}


