import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastrModule } from 'ngx-toastr';

/*** Components */
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { FooterComponent } from './footer/footer.component';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { RegistrationformComponent } from './registrationform/registrationform.component';
import { PaymentSuccessfullComponent } from './payment-successfull/payment-successfull.component';
import { EitaComponent } from './eita/eita.component';
import { LoveHopeCircleComponent } from './love-hope-circle/love-hope-circle.component';
import { EitaRegistrationComponent } from './eita-registration/eita-registration.component';

/**Service */
import { ConfigApiServiceService } from './providers/config-api-service.service';
import { ConfigureServiceService } from './providers/configure-service.service';
import { DriveServiceProvider } from './providers/drive-service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

/***Material */
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule } from "@angular/material";
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card'
import { MatDialogModule } from '@angular/material/dialog';

/***Hash Location Strategy */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { SuccessPaymentComponent } from './success-payment/success-payment/success-payment.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AdminEventsRegistrationComponent } from './admin-events-registration/admin-events-registration.component';
import { AdminEitaRegistrationComponent } from './admin-eita-registration/admin-eita-registration.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    EitaComponent,
    FooterComponent,
    ContactpageComponent,
    RegistrationformComponent,
    PaymentSuccessfullComponent,
    LoveHopeCircleComponent,
    ViewPdfComponent,
    SuccessPaymentComponent,
    EitaRegistrationComponent,
    PrintInvoiceComponent,
    AdminRegistrationComponent,
    AlertMessageComponent,
    AdminEventsRegistrationComponent,
    AdminEitaRegistrationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    PdfViewerModule,
    ToastrModule.forRoot({
    preventDuplicates: true,  
    })
  ],
  entryComponents: [
    AlertMessageComponent
  ],
  providers: [ConfigApiServiceService,
    ConfigureServiceService,
    DriveServiceProvider, HttpClientModule,
    HttpClient, DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
