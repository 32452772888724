import { Output, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { ConfigureServiceService } from './configure-service.service';
import { ConfigApiServiceService } from './config-api-service.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DriveServiceProvider {
  constructor(public http: HttpClient, public datePipe: DatePipe, public configureService: ConfigureServiceService, public configApiService: ConfigApiServiceService) {
  }
}
