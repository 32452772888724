
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.css']
})
export class AlertMessageComponent implements OnInit {
  message: any;
  title: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public configApiService: ConfigApiServiceService, public dialogRef: MatDialogRef<AlertMessageComponent>,) { }
  ngOnInit() {
    this.message = this.data;
    this.title = this.data.title;
  }

  close(e): void {
    this.dialogRef.close(e);
  }
}
