import { Component, OnInit } from '@angular/core';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
const defaultValue = 0;
@Component({
  selector: 'app-eita',
  templateUrl: './eita.component.html',
  styleUrls: ['./eita.component.css']
})
export class EitaComponent implements OnInit {
  letConnect: any;
  currentDate = new Date();
  currentEvent: any = 'Eita';
  eitaEventList: any;

  constructor(public configApiService: ConfigApiServiceService) { }

  ngOnInit() {
    this.getEvents();
  }

  /**
   * get Events List
   */
  getEvents() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullEvents.params, (respData) => {
      if (respData.length != defaultValue) {
        this.configApiService.EventsList = respData;
        let eitaEvent = this.configApiService.EventsList.filter(item => {
          const eventEndDate = new Date(item.Event_End_Date + 'T23:59:59');
          return item.Event_Type === this.currentEvent &&
            item.Event_Active === "true" &&
            eventEndDate >= this.currentDate;
        });
        this.configApiService.eitaEvent = eitaEvent;
        localStorage.setItem('EitaEvent', JSON.stringify(eitaEvent));
        if (this.configApiService.eitaEvent.length > 0) {
          this.letConnect = true;
          this.eitaEventList = eitaEvent;
        }
        else {
          this.letConnect = false;
        }
      }
      else {
        this.letConnect = false;
      }
    })
  }
}
