import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { ToastrService } from 'ngx-toastr';
const defaultValue = 0;
@Component({
  selector: 'app-contactpage',
  templateUrl: './contactpage.component.html',
  styleUrls: ['./contactpage.component.css']
})
export class ContactpageComponent implements OnInit {
  contactForm: FormGroup;// store contact form value
  locationMaster: any;
  socialMediaList: any;

  constructor(private fb: FormBuilder, public configApiService: ConfigApiServiceService ,  public toastr: ToastrService) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      just: [''],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      message: [''],
    });
  }

  ngOnInit() {
    this.getLocation();
    this.getSocialMediaList();
  }

  ngAfterViewInit() {
    document.getElementById('myTopnav').scrollIntoView();
  }

  /**
   * Submit contact Form
   */
  submit() {
    if (this.contactForm.valid) {
      this.SaveForm()
    }
    else {
      this.toastr.error('Please Fill All Mandatory Fields', '', {
        positionClass: 'toast-bottom-center',
        timeOut: 3000,
      });
      this.markFormGroupAsTouched(this.contactForm);
    }
  }

  /**
  * send mail through mail
  */
  // sendmail() {
  //   let mailScheduler = {};
  //   var fromEmailId = "it.embryo@gmail.com";
  //   var toEmailId = "arunmichealraj78@gmail.com"
  //   var mailBody = "name" + this.contactForm.value.name + this.contactForm.value.just + this.contactForm.value.phone + this.contactForm.value.email + this.contactForm.value.message;
  //   var SubjectMessage = "OTP Generate"
  //   mailScheduler = { 'TOID': toEmailId, 'FROMID': fromEmailId, 'BODY': mailBody, 'ShareType': 1, 'SUBJECT': SubjectMessage };
  //   let objPushParams = Object.assign({}, this.configApiService.lhcApi.pushMailShareUser.params, mailScheduler);
  //   this.configApiService.pushRequest(objPushParams, this.mailSentResponseStatus);
  // }

  /**
  * Save Contact Form
  */
  SaveForm() {
    let postData = {
      Customer_Mobile: this.contactForm.value.phone,
      Customer_Name: this.contactForm.value.name,
      Customer_email: this.contactForm.value.email,
      Message: this.contactForm.value.message,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.Lhc_Contact_Master.params, postData);
    this.configApiService.pushRequest(objData, this.reqSucess)
  }

  reqSucess = ((resData) => {
    if (resData) {
      alert("Contact Form Successfully!");
    }
  })


  mailSentResponseStatus = ((responsedata) => {
    if (responsedata == "SUCCESS") {
      alert("success");
    }
  });


  /**
   * Submit contact Form
   */
  getLocation() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullLocationMaster.params, (respData) => {
      if (respData.length != defaultValue) {
        this.locationMaster = respData;
      }
    });
  }

  /**
  * get Document List
  */
  getSocialMediaList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullSocialMedia.params, (respData) => {
      if (respData.length != defaultValue) {
        this.socialMediaList = respData;
      }
    });
  }

  /**
 * Validation for form 
 * @param formGroup 
 */
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
