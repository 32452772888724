import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentSuccessfullComponent } from '../payment-successfull/payment-successfull.component';
import { ViewPdfComponent } from '../view-pdf/view-pdf.component';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
const defaultValue = 0;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog, public configApiService: ConfigApiServiceService) { }

  ngOnInit() {
    this.getDocumentationList()
    this.getSocialMediaList();
  }

  /**
  * show Popup 
  */
  openDialog(): void {
    const dialogRef = this.dialog.open(PaymentSuccessfullComponent, {
      width: '650px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
  * get Document List
  */
  getDocumentationList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullDocument.params, (respData) => {
      if (respData.length != defaultValue) {
        this.configApiService.documentList = respData;
      }
    });
  }

  /**
  * get Document List
  */
  getSocialMediaList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullSocialMedia.params, (respData) => {
      if (respData.length != defaultValue) {
        this.configApiService.socialMediaList = respData;
      }
    });
  }

  openPdf(params): void {
    console.log(params, "pop event e")
    const dialogRef = this.dialog.open(ViewPdfComponent, {
      data: { url: params },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
