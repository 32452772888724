import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigApiServiceService } from '../providers/config-api-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  activePage = 'hope';

  constructor(private route: ActivatedRoute, public configApiService: ConfigApiServiceService) { }

  ngOnInit() {
    this.route.url.subscribe(url => {
      if (url.length > 0) {
        this.activePage = url[0].path;
      }
      else {
        this.activePage = 'hope';
      }
    });
  }
}
