import { Injectable } from "@angular/core";


declare var nw: any;
@Injectable({
  providedIn: "root",
})
export class ConfigureServiceService {
  __serverBase: any;
  deviceId: string;
  accessToken: string = "";
  version: string;
  exportFolder: any = '';
  _debugMode: boolean = true;
  deviceReady: boolean = true;
  serverMode: string = "dev";
  //devServer: string = "http://192.168.65.212:82/";
  devServer: string = "https://prodcast.cloud:3780";
  prodServer: string = "https://time.starport.ca:62228";
  localServer: string = "http://localhost:8100/";
  demoServer: string = "https://time.starport.ca:62229";
  qaServer: string = "https://time.starport.ca:62223";
  driveepc: string = "http://drive.eprodcast.com";
  drive1: string = "https://prodcast.cloud";
  drive2: string = "https://epcdrive.azurewebsites.net/";
  epcService: string = "EPC_V4.svc";
  urlParams: any;

  appState: any;
  isAdmin: boolean = false;
  login: any = {};
  defServerMode: string = "dev";
  desktype: string = "default";
  private studiocode;
  objFolder: any;
  constructor() {
    this.version = "0.0.1";
    this.deviceId = "web";
    this.urlParams = this.getUrlVars();
    if (this.urlParams) {
      this.defServerMode = this.urlParams["server"] || "dev";
      this.desktype = this.urlParams["desktype"] || "default";
    }
    this.setServerMode(this.defServerMode || "dev");
  }
  getUrlVars() {
    var vars: any = [],
      hash;
    if (window["nw"]) {
      var gui = nw.require("nw.gui");
      var app = gui.App;
      hash = app.argv;
      if (hash.length > 0) {
        for (var i = 0; i < hash.length; i++) {
          switch (hash[i]) {
            case "mode":
              vars["mode"] = hash[i + 1];
              break;
            case "json":
              vars["json"] = hash[i + 1];
              break;
            case "theme":
              vars["theme"] = hash[i + 1];
              break;
            case "server":
              vars["server"] = hash[i + 1];
              break;
            case "desktype":
              vars["desktype"] = hash[i + 1];
              break;
          }
        }
      }
      return vars;
    }
    var hashes = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    for (var j = 0; j < hashes.length; j++) {
      hash = hashes[j].split("=");
      vars.push(hash[0]);
      vars[hash[0]] = hash[1] ? hash[1].split("#")[0] : hash[1];
    }
    return vars;
  }
  getDeskType() {
    return this.desktype;
  }
  getCurrentAppState() {
    return this.appState;
  }
  getServerMode() {
    return this.serverMode;
  }

  setServerMode(mode, serv = "") {
    this.serverMode = this.defServerMode && !serv ? this.defServerMode : mode;
    if (this.serverMode == "dev") {
      this.__serverBase = this.devServer;
    } else if (this.serverMode == "local") {
      this.__serverBase = this.urlParams["host"]; //this.localServer;
      console.log("local", this.__serverBase);
    } else if (this.serverMode == "demo") {
      this.__serverBase = this.demoServer;
    } else if (this.serverMode == "prod") {
      this.__serverBase = this.prodServer;
    } else if (this.serverMode == "qa") {
      this.__serverBase = this.qaServer;
    } else if (this.serverMode == "drive1") {
      this.__serverBase = this.drive1;
    } else if (this.serverMode == "drive2") {
      this.__serverBase = this.drive2;
    } else if (this.serverMode == "driveepc") {
      this.__serverBase = this.driveepc;
    } else {
      this.serverMode = "prod";
      this.__serverBase = serv || this.devServer;
    }
    window["host"] = this.__serverBase;
    window["service"] = this.__serverBase + "/" + this.epcService + "/";
  }
  getServerDir() {
    let mode = this.serverMode;
    let dir = "epc";
    if (mode == "dev") {
      dir = "epc";
    } else if (mode == "local") {
      dir = "";
    }
    return dir;
  }
  getHost() {
    // let mode=this.serverMode;
    // let dir=this.getServerDir();
    let host = this.__serverBase + "/" + this.epcService + "/";
    return host;
  }
  getStudioHost() {
    // let mode=this.serverMode;
    // let dir=this.getServerDir();
    let host = this.__serverBase + "/EPCSTUDIO.svc/";
    return host;
  }
  getAppHost(app) {
    // let mode=this.serverMode;
    // let dir=this.getServerDir();
    let sb = this.__serverBase;
    if (app && app.host) {
      sb = app.host;
    }
    let host = sb; // + "/EPC_V3.svc/";
    return host;
  }

  getAppHostUrl() {
    return window.location["origin"] + window.location["pathname"];
  }

  getUrlBase() {
    return this.__serverBase;
  }

  getVersion() {
    return this.version;
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }
  getUserLevel() {
    return this.isAdmin ? "admin" : "user";
  }
  isUserAdmin() {
    return this.isAdmin;
  }
  setUserLevel(admin) {
    this.isAdmin = admin;
  }
  setLoginData(u, p) {
    this.login.user = u;
    this.login.pass = p;
  }
  getLoginData() {
    return this.login;
  }
  setStudio(v) {
    this.studiocode = v;
  }
  getStudio() {
    return this.studiocode;
  }
  public getDeviceId(): string {
    return this.deviceId;
  }
  isDesktop() {
    var boo = false;
    try {
      boo = nw && nw.Window;
    } catch (e) {
      boo = false;
    }
    return boo;
  }
  setLoginUserID(userID) {
    this.login.userID = userID;
  }

  getLoginUserID(): any {
    return this.login.userID;
  }
  getAccessToken(): any {
    return this.accessToken;
  }
}
