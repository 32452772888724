import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentSuccessfullComponent } from '../payment-successfull/payment-successfull.component';
import { ConfigApiServiceService } from '../providers/config-api-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
const defaultValue = 0;
const invoiceNumber = '20230000';
declare var Razorpay: any;
declare var Stripe: any;

@Component({
  selector: 'app-admin-registration',
  templateUrl: './admin-registration.component.html',
  styleUrls: ['./admin-registration.component.css']
})
export class AdminRegistrationComponent implements OnInit {
  @ViewChild('dialogRef', { static: false })
  dialogRef!: TemplateRef<any>
  occupationList: any; //store Occupation List
  relationList: any;//store relation List
  timeSlot: any;//store timeSlot List
  dayList: any;//store  day List
  therapyType: any;//store therapy Type List
  languageList: any;//store language List
  referralList: any;//store referral List
  registrationForm: FormGroup;
  checkboxChecked: boolean = false;
  postData: any;
  reqSlot: any;
  reqDay: any;
  theraphyTypeId: any;
  therapyPrice: any;
  //configURl: any = 'https://192.168.0.106:3784/payment'; // Local_Url
  //sendSMSUrl: any = 'https://localhost:3784/sendSMS'; // Local_Url
  configURl: any = 'https://drive.prodcast.cloud:3784/payment'; // Live_Url
  sendSMSUrl: any = 'https://drive.prodcast.cloud:3784/sendSMS'; // Live_Url
  StripePaymentUrl: any = 'https://drive.prodcast.cloud:3784/Stripepayment'; // Live_Url
  //StripePaymentUrl: any = 'https://localhost:3784/Stripepayment'; // Local_Url
  sendUrl: any = 'https://drive.prodcast.cloud:3784/sendMail'; // Live_Url
  //sendUrl: any = 'https://localhost:3784/sendMail'; // Local_Url
  patientNo: any;
  orderData: {
    key: string; // Enter the Key ID generated from the Dashboard
    amount: any; // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: any; order_id: any; //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (response: any) => void;
  };
  sesstion: any;
  sesstionId: any;
  cName: any;
  cDOB: any;
  CLoction: any;
  CEmail: any;
  Enum: any;
  gender: any;
  language: any;
  Cnumber: any;
  occupation: any;
  reason: any;
  relationship: any;
  sourceReferral: any;
  Patient_no: any;
  invoiceNo: any;
  patientName: any;
  paymentRes: any;
  cusEmailId: any;
  dateFormat = 'yyyy-MM-dd';
  currentDate = new Date();
  customerId: any;
  enable: any = "false";
  registeredSuccessfully: boolean = false;
  adminEmailId: any = "hello@thelovehopecompany.com";
  therapyTypeName: any;
  showQRCode: boolean = false;
  newCouponCode: any;
  couponCode: any;
  transactionId: any;
  showTransction: boolean = false;
  phoneNumber: any;
  modeList: any;
  mode: any;
  modeofTherapy: any;
  private stripe: any;
  errormsg: boolean = false;
  expiredmsg: boolean = false;
  checkPaymentmethod: boolean = false;
  selectPayment: any;
  customer: any;
  ccEmailId: any = "admin@thelovehopecompany.com";
  paymentStatus: any = "UnPaid";

  constructor(
    private router: Router,
    public datePipe: DatePipe,
    private http: HttpClient,
    public configApiService: ConfigApiServiceService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public toastr: ToastrService
  ) {
    this.stripe = Stripe('pk_live_51NLPLuSBD5puc8V6VxIWY56IOkpKPhAjvYXlnCRGHyfYs9ani4GDozu4ckp8EDgMXlRM1U5d7kkkbnvxEphaKSRN00PX5pFIRa'); //Live Keys
    //this.stripe = Stripe('pk_test_51NLNLISAvZBYHudzm5UsIdpwQQz96O1qlBSF9IIjPG7T93GqpjgJx2sZFeVSkwfP2DghhjfydoA4g7a6VU1N2ySL004IZBsWYw'); //Local Keys
    this.registrationForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      theraphyType: ['', [Validators.required]],
      day: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      currentLocation: ['', [Validators.required]],
      occupation: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      langauagePreference: ['', [Validators.required]],
      time: ['', [Validators.required]],
      emergencyCNumber: ['', [Validators.pattern(/^\d{10}$/)]],
      Source_of_referral: ['', [Validators.required]],
      newCode: [''],
      modeTherapy: ['', [Validators.required]],
    });
  }
  ngOnInit() {
    this.getOccupationList();
    this.getRelationList();
    this.getTimeSlot();
    this.getDayList();
    this.getTherapyType();
    this.getSourceReferralList();
    this.getLanguageList();
    this.getTransaction();
    this.getCouponCode();
    this.getModeTherapy();
  }
  ngAfterViewInit() {
    document.getElementById('myTopnav').scrollIntoView();
    localStorage.removeItem('eitaDeatils');
    localStorage.removeItem('eventsDeatils');
  }

  /**
   * Customer registrartion form 
   */
  submit() {
    if (this.registrationForm.valid && !this.errormsg && !this.expiredmsg) {
      this.reqDay = this.registrationForm.controls.day.value;
      this.reqSlot = this.registrationForm.controls.time.value;
      this.theraphyTypeId = this.registrationForm.controls.theraphyType.value;
      this.patientName = this.registrationForm.controls.name.value;
      this.cusEmailId = this.registrationForm.controls.email.value;
      this.newCouponCode = this.registrationForm.controls.newCode.value;
      this.phoneNumber = this.registrationForm.controls.phone.value;
      this.modeofTherapy = this.registrationForm.controls.modeTherapy.value;
      this.postData = {
        Current_Location: this.registrationForm.controls.currentLocation.value || '',
        Cus_DOB: this.registrationForm.controls.dob.value || '',
        Cus_Name: this.registrationForm.controls.name.value || '',
        Email_id: this.registrationForm.controls.email.value || '',
        Emergency_contact_no: this.registrationForm.controls.emergencyCNumber.value || '',
        Gender: this.registrationForm.controls.gender.value || '',
        Language_preference: this.registrationForm.controls.langauagePreference.value || 0,
        Mobile_no: this.registrationForm.controls.phone.value || 0,
        Occupation: this.registrationForm.controls.occupation.value || 0,
        Reason_for_seeking_help: this.registrationForm.controls.reason.value || '',
        Source_of_referral: this.registrationForm.controls.Source_of_referral.value || 0,
        customer_Master_Id: this.customerId || '',
        Patient_no: this.Patient_no || '',
        Payment_Status: this.paymentStatus,
      }
      this.customer = this.postData;
      let objData = Object.assign({}, this.configApiService.lhcApi.pushLoho_Customer_Master.params, this.postData);
      this.configApiService.pushRequest(objData, this.resSuccess)
    }
    else {
      if (this.errormsg || this.expiredmsg) {
        this.toastr.error('Please Enter Valid Coupon code', '', {
          positionClass: 'toast-bottom-center',
          timeOut: 3000,
        });
      } else {
        this.toastr.error('Please Fill All Mandatory Fields', '', {
          positionClass: 'toast-bottom-center',
          timeOut: 3000,
        });
      }
      this.markFormGroupAsTouched(this.registrationForm);
    }
  }


  /**
   * Response from registration create payment screen  
   */
  resSuccess = ((resData) => {
    this.choosePayment();
    if (this.Patient_no) {
      this.Patient_no = this.Patient_no;
      if (this.selectPayment == "paybyCounter") {
        this.showPaymentStatus()
        //this.sessionMaster();
      }
    } else {
      this.Patient_no = resData['customer_Master5076'].patientNo;
      this.customerId = resData['customer_Master5076'].customer_Master_Id;
      if (this.selectPayment == "paybyCounter") {
        this.showPaymentStatus()
        //this.sessionMaster();
      }
    }
    this.checkboxChecked = !this.checkboxChecked;
  })

  sessionMaster() {
    this.postData = {
      "Patient_No": this.Patient_no,
      "Patient_Name": this.patientName,
      "Req_Day": this.reqDay,
      "Req_Slot": this.reqSlot,
      "Therapy_type": this.theraphyTypeId,
      "Initial_Payment": this.therapyPrice,
      "Mode_of_Therapy": this.modeofTherapy,
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.pushsession_master.params, this.postData);
    this.configApiService.pushRequest(objData, this.resSession)
  }

  /**
   * Get session Id
   */
  resSession = ((resData) => {
    if (resData) {
      this.sesstionId = resData.session_master5078.session_master_Id
      //this.sendSMS();
      this.updateTransaction();
    }
  })

  /**
   * Post Transaction Data
   */
  updateTransaction() {
    this.invoiceNo = parseInt(this.invoiceNo) + 1;
    this.postData = {
      Amount_paid: this.therapyPrice,
      Patient_Name: this.patientName || '',
      Date_of_transacion: this.datePipe.transform(this.currentDate, this.dateFormat),
      Gw_Trans_Ref_id: "",
      Gw_trans_Status: "",
      Modeofpayment: "2",
      Invoice_no: this.invoiceNo,
      Patient_No: this.Patient_no,
      Coupon: this.newCouponCode,
      Trans_Ref_id: this.transactionId || 0,
      session_id: this.sesstionId,
      Trans_Email_status: "true",
      Trans_Email_date_and_time: this.datePipe.transform(this.currentDate, this.dateFormat),
    }
    let objData = Object.assign({}, this.configApiService.lhcApi.Loho_Transaction.params, this.postData);
    this.configApiService.pushRequest(objData, this.resTransaction)
  }

  /**
   * Get Transaction Status
   */
  resTransaction = ((resData) => {
    if (resData != 'FAIL') {
      this.sendMail(this.customer.Email_id)
      this.paymentScreen();
    }
  })

  paymentScreen() {
    this.showQRCode = false;
    this.opendialog();
  }

  /***
   * method for openpoup
   */
  opendialog() {
    this.dialog.open(this.dialogRef, { disableClose: true, })
  }

  /***
  * method for closepopup
  */
  close() {
    this.dialog.closeAll()
    this.router.navigate(['/']);
  }

  /**
   * choose Payment Amount
   */
  choosePayment() {
    if (this.theraphyTypeId) {
      let theraphyType = this.therapyType.filter(item => item.Loho_Treatmenttype_Id == this.theraphyTypeId)
      this.therapyPrice = theraphyType[0].Therapyprice;
      this.therapyTypeName = theraphyType[0].Treatment_type
      if (this.couponCode.length > 0) {
        let result = this.couponCode.filter(obj => obj.Coupon_code == this.newCouponCode);
        result.length > 0 ? this.therapyPrice = result[0].Value : this.therapyPrice = this.therapyPrice;
      }
      else {
        this.therapyPrice = this.therapyPrice;
      }
    }
  }

  toggleButton() {
    this.checkboxChecked = !this.checkboxChecked;
  }

  /**
   * Validation for form 
   * @param formGroup 
   */
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
   * get Occupation List
   */
  getOccupationList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullOccupationList.params, (respData) => {
      if (respData.length != defaultValue) {
        this.occupationList = respData;
      }
    });
  }

  /**
   * get Relation List
   */
  getRelationList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullRelationList.params, (respData) => {
      if (respData.length != defaultValue) {
        this.relationList = respData;
      }
    });
  }

  /**
  * get Time slot
  */
  getTimeSlot() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullTimeSlot.params, (respData) => {
      if (respData.length != defaultValue) {
        this.timeSlot = respData;
      }
    });
  }

  /**
  * get Day List
  */
  getDayList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullDayList.params, (respData) => {
      if (respData.length != defaultValue) {
        this.dayList = respData;
      }
    });
  }

  /**
  * get Coupon Code
  */
  getCouponCode() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullCoupon.params, (respData) => {
      if (respData.length != defaultValue) {
        this.couponCode = respData;
      }
    });
  }

  /**
  * get Therapy Type
  */
  getTherapyType() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullTherapyType.params, (respData) => {
      if (respData.length != defaultValue) {
        this.therapyType = respData;
        this.therapyType.sort(function (a, b) {
          var therapyTypeA = a.Treatment_type.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          var therapyTypeB = b.Treatment_type.toUpperCase();
          if (therapyTypeA < therapyTypeB) {
            return -1;
          }
          if (therapyTypeA > therapyTypeB) {
            return 1;
          }
          return 0;
        });
      }
    });
  }

  /**
  * get Transaction Summary
  */
  getTransaction() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pushLhc_Invoice.params, (respData) => {
      if (respData.length != defaultValue) {
        if (respData[0].LatestInvoiceNumber) {
          this.invoiceNo = respData[0].LatestInvoiceNumber;
        } else {
          this.invoiceNo = invoiceNumber;
        }
      }
    });
  }


  /**
   * get Language List 
   */
  getLanguageList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullLanguageList.params, (respData) => {
      if (respData.length != defaultValue) {
        this.languageList = respData;
        this.languageList.sort(function (a, b) {
          var languageA = a.Preferred_Language.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          var languageB = b.Preferred_Language.toUpperCase();
          if (languageA < languageB) {
            return -1;
          }
          if (languageA > languageB) {
            return 1;
          }
          return 0;
        });
      }
    });
  }

  /**
  * get Source referral List 
  */
  getSourceReferralList() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.pullSourceReferralList.params, (respData) => {
      if (respData.length != defaultValue) {
        this.referralList = respData;
        this.referralList.sort(function (a, b) {
          var referralA = a.Source_of_Referral.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          var referralB = b.Source_of_Referral.toUpperCase();
          if (referralA === 'OTHERS') {
            return 1;
          } else if (referralB === 'OTHERS') {
            return -1;
          } else {
            if (referralA < referralB) {
              return -1;
            } else if (referralA > referralB) {
              return 1;
            }
            return 0;
          }
        });
      }
    });
  }

  /**
   * Validate Coupon Code
   */
  validateCoupon(e) {
    if (e.target.value) {
      let code = e.target.value
      if (this.couponCode.length > 0) {
        let result = this.couponCode.filter(obj => obj.Coupon_code == code);
        if (result.length > 0) {
          this.errormsg = false;
          const eventEndDate = new Date(result[0].Valid_till + 'T23:59:59');
          if (eventEndDate < this.currentDate) {
            this.expiredmsg = true
            this.errormsg = false
          }
          else {
            this.expiredmsg = false
          }
        }
        else {
          this.errormsg = true
          this.expiredmsg = false
        }
      }
    }
    else {
      this.errormsg = false
      this.expiredmsg = false
    }
  }

  /**
  * get Customer Details
  */
  fetchCustomer(e) {
    if (e.target.value) {
      var params = {
        Mobile_no: e.target.value
      }
      let objData = Object.assign({}, this.configApiService.lhcApi.pullCustomerMaster.params, params);
      this.configApiService.pullRequest(objData, (respData) => {
        if (respData.length != defaultValue) {
          this.enable = "true";
          var customerDetails = respData[0];
          this.customerId = customerDetails.customer_Master_Id
          this.Patient_no = customerDetails.Patient_no
          this.cName = customerDetails.Cus_Name;
          this.cDOB = customerDetails.Cus_DOB;
          this.CLoction = customerDetails.Current_Location;
          this.CEmail = customerDetails.Email_id;
          this.Enum = customerDetails.Emergency_contact_no;
          this.gender = customerDetails.Gender;
          this.language = customerDetails.Language_preference;
          this.Cnumber = customerDetails.Mobile_no;
          this.occupation = customerDetails.Occupation;
          this.reason = customerDetails.Reason_for_seeking_help;
          this.sourceReferral = customerDetails.Source_of_referral;
          this.mode = customerDetails.modeList;
        }
        var occupationId = this.occupationList.filter(item => item.OccuPation == this.occupation)
        this.registrationForm.controls.occupation.setValue(occupationId[0].Loho_Occupation_Id);

        var langauageId = this.languageList.filter(item => item.Preferred_Language == this.language)
        this.registrationForm.controls.langauagePreference.setValue(langauageId[0].Loho_Language_Id);

        var Source_of_referralId = this.referralList.filter(item => item.Source_of_Referral == this.sourceReferral)
        this.registrationForm.controls.Source_of_referral.setValue(Source_of_referralId[0].Loho_Referral_Id);
      });
    }
  }

  /**
  * close popup
  */
  closePopup() {
    this.registeredSuccessfully = false;
    this.router.navigate(['/']);
  }

  /**
  * Payment mode events
  */
  enablepayment(e) {
    this.selectPayment = e.target.value;
    this.checkPaymentmethod = true;
  }

  /**
  * Copy Function
  */
  copyInputMessage() {
    var textToCopy = document.getElementById("myInput").innerText;

    /* Create a temporary input element */
    var tempInput = document.createElement("input");

    /* Assign the text to be copied to the input element */
    tempInput.value = textToCopy;

    /* Append the input element to the document body */
    document.body.appendChild(tempInput);

    /* Select the text inside the input element */
    tempInput.select();

    /* Copy the selected text to the clipboard */
    document.execCommand("copy");

    /* Remove the temporary input element */
    document.body.removeChild(tempInput);

    /* Alert the user that the text has been copied */
    alert("Text copied to clipboard");
  }

  /**
  * get Mode of therapy
  */
  getModeTherapy() {
    this.configApiService.pullRequest(this.configApiService.lhcApi.Loho_ModeTherapy.params, (respData) => {
      if (respData.length != defaultValue) {
        this.modeList = respData;
      }
    });
  }

  /**
   * Create Stripe Payment checkout screen
   */
  stripeCheckout() {
    var modeId = this.modeList.filter(item => item.Mode_Id == this.modeofTherapy)

    /**
     * Session details Local Storage 
     */
    const sessionData = {
      "Patient_No": this.Patient_no,
      "Patient_Name": this.patientName,
      "Req_Day": this.reqDay,
      "Req_Slot": this.reqSlot,
      "Therapy_type": this.theraphyTypeId,
      "Initial_Payment": this.therapyPrice,
      "Mode_of_Therapy": this.modeofTherapy,
    }
    localStorage.setItem('sessionDeatils', JSON.stringify(sessionData));

    /**
    * Customer details Local Storage 
    */
    const customer = {
      clientName: this.patientName,
      clientId: this.Patient_no,
      therapyPrice: this.therapyPrice,
      invoiceNumber: parseInt(this.invoiceNo) + 1,
      sesstionId: this.sesstionId || 0,
      emailId: this.cusEmailId,
      couponCode: this.newCouponCode,
      therapyTypeName: this.therapyTypeName,
      modeofTherapy: modeId[0].Mode,
      phnno: this.phoneNumber,
    };
    localStorage.setItem('CustomerDeatils', JSON.stringify(customer));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http.post(this.StripePaymentUrl, { customer }, { headers })
      .subscribe(
        (session: any) => {
          return this.stripe.redirectToCheckout({ sessionId: session.id })
            .then((result: any) => {
              if (result.error) {
                alert(result.error.message);
              }
            })
            .catch((error: any) => {
              console.error('Error:', error);
            });
        },
        (error: any) => {
          console.error('Error:', error);
        }
      );
  }

  /**
  * Send Mail api
  */
  // sendMail(mailId) {
  //   var mailSubject = "Your Registration with The Love Hope Company is Now Complete!";
  //   var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.customer.Cus_Name + "</h4>" + "<p> Fantastic news! Your payment has been successfully processed, and we are thrilled to confirm that your registration with The Love Hope Company. We deeply appreciate your decision to choose our services, and we are excited to embark on this transformative journey with you. Together, let's nurture your mental well-being and unlock a happier, healthier you! </p><p>Our dedicated team will contact you shortly within 24-48 working hours to match you with the right therapist who best aligns with your needs and preferences. We understand the importance of finding the perfect fit, and we want to ensure that you receive the highest quality of care. Further details regarding your therapist assignment, including their availability, will be shared with you via email.</p><table style='width:50%;border: 1px solid black'><tr><th style='border: 1px solid black'>Name</th><th style='border: 1px solid black'>" + this.customer.Cus_Name + "</th></tr><tr><th style='border: 1px solid black'>Amount</th><th style='border: 1px solid black'>" + this.therapyPrice + "</th></tr></table><p>If you have any immediate questions or concerns, please don't hesitate to reach out to us at +91 9677029999. We are here to assist you every step of the way.</p><p>Once again, thank you for choosing The Love Hope Company. We look forward to supporting you on your path to wellness.</p><div>With warmest regards,<br>The Love Hope Company</div></body></html>"

  //   var param = {
  //     TOID: mailId,
  //     CC: this.ccEmailId,
  //     BCC: "",
  //     FROMID: this.adminEmailId,
  //     SUBJECT: mailSubject,
  //     BODY: _mailhtmlbody,
  //   };
  //   var obj = {};
  //   obj['data'] = JSON.stringify(param);
  //   var pa = JSON.stringify(obj);
  //   this.http.post(this.sendUrl, pa).subscribe((response: any) => {
  //     console.log(response, "response")
  //   })
  // }

  /**
* send mail through mail
*/
  sendMail(mailId) {
    let mailScheduler = {};
    var FROMID = this.adminEmailId;
    var TOID = mailId;
    var CC = this.adminEmailId;
    var BCC = this.ccEmailId
    var mailSubject = "Registration Successful- Book A Couch!";
    var _mailhtmlbody = "<html><head></head><body><h4>Dear " + this.customer.Cus_Name + "</h4><p>Welcome to The Love Hope Company!</p> <p>Your registration for Book a Couch has been successfully processed.</p><table style='width:50%;border: 1px solid black'><tr><th style='border: 1px solid black'>Name</th><th style='border: 1px solid black'>" + this.customer.Cus_Name + "</th></tr><tr><th style='border: 1px solid black'>Amount</th><th style='border: 1px solid black'>" + this.therapyPrice + "</th></tr></table><p> Within 24-48 working hours, our team will contact you to match you with a certified professional who best aligns with your specific needs and preferences.</p><p>If you have any immediate questions or concerns, please don't hesitate to drop a text at +91 9677029999. We are here to assist you every step of the way.</p><div>With Love and Hope,<br>Team TLHC</div><img style='width:20%' src='https://drive.prodcast.cloud:3784/uploads/Layer394.jpg'></body></html>"
    mailScheduler = { 'TOID': TOID, 'FROMID': FROMID, 'CC': CC, "BCC": BCC, 'BODY': _mailhtmlbody, 'ShareType': 1, 'SUBJECT': mailSubject };
    let objPushParams = Object.assign({}, this.configApiService.lhcApi.pushMailShareUser.params, mailScheduler);
    this.configApiService.pushRequest(objPushParams, this.mailSentResponseStatus);
  }

  mailSentResponseStatus = ((responsedata) => {
    if (responsedata == "SUCCESS") {
      console.log(responsedata, "responsedata")
    }
  });

  /**
   * Show payment status screen 
   */
  showPaymentStatus() {
    let message = "Please confirm if ₹ " + this.therapyPrice + " the payment is received ?"
    let title = "Event"
    this.openAlert(message, title, (result) => {
      if (result == "yes") {
        this.postData = {
          Current_Location: this.registrationForm.controls.currentLocation.value || '',
          Cus_DOB: this.registrationForm.controls.dob.value || '',
          Cus_Name: this.registrationForm.controls.name.value || '',
          Email_id: this.registrationForm.controls.email.value || '',
          Emergency_contact_no: this.registrationForm.controls.emergencyCNumber.value || '',
          Gender: this.registrationForm.controls.gender.value || '',
          Language_preference: this.registrationForm.controls.langauagePreference.value || 0,
          Mobile_no: this.registrationForm.controls.phone.value || 0,
          Occupation: this.registrationForm.controls.occupation.value || 0,
          Reason_for_seeking_help: this.registrationForm.controls.reason.value || '',
          Source_of_referral: this.registrationForm.controls.Source_of_referral.value || 0,
          customer_Master_Id: this.customerId || '',
          Patient_no: this.Patient_no || '',
          Payment_Status: "Paid Successfull",
        }
        this.customer = this.postData;
        let objData = Object.assign({}, this.configApiService.lhcApi.pushLoho_Customer_Master.params, this.postData);
        this.configApiService.pushRequest(objData, this.updateResSuccess)
      } else {
        this.router.navigate(['/'])
      }
    })
  }

  updateResSuccess = ((resData) => {
    if (resData != 'FAIL') {
      this.sessionMaster();
    }
  })

  /***
   * Method for open Registration  success
   */
  openAlert(message, title, callback): void {
    const dialogRef = this.dialog.open(AlertMessageComponent, {
      data: {
        message: message,
        title: title
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    });
  }
}

