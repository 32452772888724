import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { ConfigureServiceService } from "./configure-service.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import 'rxjs/Rx';
import { DatePipe } from '@angular/common';
import { PaymentSuccessfullComponent } from "../payment-successfull/payment-successfull.component";
import { MatDialog } from "@angular/material";
@Injectable()
export class ConfigApiServiceService {
  lhcApi: any = {};
  respMessage: any = '';
  project_config: any;
  fetchUserLicenceAccessApi: string;
  driveURl: string = '';
  host: string;
  summaryApi: string;
  summaryListApi: string;
  postaddApi: string;

  exportApi: {};
  alertMessage: any;
  dateFormat = 'yyyy-MM-dd';
  currentDate: any;
  genDate: string;
  payment_Id: any;
  patient_Name: any;
  order_Id: any;
  invoice_No: any;
  status: any;
  documentList: any;
  socialMediaList: any;
  EventsList: any;
  transaction: any;
  eitaEvent: any;
  etiaTransaction: any;
  mailToClientReg: boolean = false;
  mailToEitaReg: boolean = false;
  showEitaUpiSuccess: boolean = false;
  showEitaReg: boolean = false;

  constructor(
    public datePipe: DatePipe,
    private _http: HttpClient,
    private dialog: MatDialog,
    //private HttpHeaders: HttpHeaders,
    public configureService: ConfigureServiceService
  ) {
    this.host = this.configureService.getHost();

    // HARDCODED VALUE

    this.lhcApi = {
      'pushMailShareUser': {
        'params': {
          'client': "StudioApp11",
          'rptname': "Drive_share_mail",
          'ShareType': "1",
          'TOID': "",
          'CC': "",
          'BCC': "",
          'FROMID': "",
          'SUBJECT': "",
          'BODY': "",
          'ShareID': "",
          'userid': "3280"
        }
      },
      "pullOccupationList":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Occupation5053",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullLocationMaster":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "LOHO_Branch5010",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullRelationList":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Relationship5054",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullTimeSlot":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "LOHO_TherapySlot5043",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullDayList":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Day5046",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullTherapyType":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Treatmenttype5015",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullLanguageList":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Language5052",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullSourceReferralList":
      {
        params: {
          start: "1",
          end: "1000",
          userid: "1",
          rptname: "Loho_Referral5055",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pullCustomerMaster":
      {
        params: {
          start: "1",
          Mobile_no: "",
          end: "1000",
          userid: "1",
          rptname: "customer_Master5076",
          client: "LOVEandHOPEcompany1393",
        }
      },
      "pushLoho_Customer_Master":
      {
        params: {
          Created_By: "",
          Created_date: "1900-01-01",
          Current_Location: "",
          Cus_DOB: "",
          Cus_Name: "",
          Email_id: "",
          Emergency_contact_name: "",
          Emergency_contact_no: "",
          Gender: "",
          Language_preference: "",
          customer_Master_Id: "",
          Mobile_no: "",
          Modified_By: "",
          Modified_date: "1900-01-01",
          Occupation: "",
          Patient_no: "",
          Reason_for_seeking_help: "",
          Relatonship: "",
          Current_Therapist: "",
          Source_of_referral: "",
          Mode_of_Payment: "",
          Payment_Status: "",
          Tag: "",
          client: "LOVEandHOPEcompany1393",
          rptname: "customer_Master5076",
          status: "1",
          userid: "1",
        }
      },
      "pushsession_master":
      {
        params: {
          Additional_Payment: "",
          Alloc_Date: "",
          Alloc_Slot: "",
          Alloc_Time: "",
          Created_By: "",
          Created_date: "1900-01-01",
          Did_Payment_Mode: "",
          Diff_Amount_Payment_Date_and_Time: "",
          Diff_Amount_Payment_Status: "",
          Initial_Payment: "",
          Modified_By: "",
          Modified_date: "",
          Patient_No: "",
          Patient_Name: "",
          Patient_Present: "",
          Req_Day: "",
          Req_Slot: "",
          Session_Duration: "",
          Session_End_Time: "",
          Session_Start_Time: "",
          Mode_of_Therapy: "",
          Session_Status: 0,
          Tag: "",
          Therapist_Id: "",
          Therapist_Name: "",
          Therapist_Status: "",
          Therapy_type: "",
          Therapist_observation: "",
          client: "LOVEandHOPEcompany1393",
          rptname: "session_master5078",
          session_master_Id: '',
          status: "",
          userid: "1"
        }
      },
      "Loho_Transaction":
      {
        params: {
          Amount_paid: "",
          Created_By: "",
          Created_date: "1900-01-01",
          Date_of_transacion: "",
          Gw_Trans_Ref_id: "",
          Gw_trans_Status: "",
          Invoice_no: "",
          Loho_Transaction_Id: "",
          Modeofpayment: "0",
          Modified_By: "",
          Modified_date: "1900-01-01",
          Patient_No: "",
          Patient_Name: "",
          Tag: "",
          Trans_Email_date_and_time: "",
          Trans_Email_status: "",
          Trans_Ref_id: "",
          Coupon: "",
          client: "LOVEandHOPEcompany1393",
          rptname: "Loho_Transaction5067",
          session_id: "",
          status: "",
          userid: "1",
        }
      },
      "Loho_Transaction_Summary":
      {
        params: {
          start: 1,
          end: 1000,
          userid: 1,
          rptname: "Loho_Transaction5067",
          client: "LOVEandHOPEcompany1393"
        }
      },
      "Lhc_Contact_Master":
      {
        params: {
          Created_By: "",
          Created_date: "1900-01-01",
          Customer_Mobile: 0,
          Customer_Name: "",
          Customer_email: "",
          Loho_Enquiry_Id: "",
          Message: "",
          Modified_By: "",
          Modified_date: "1900-01-01",
          Tag: "",
          client: "LOVEandHOPEcompany1393",
          rptname: "Loho_Enquiry5084",
          status: "1",
          userid: "1"
        }
      },
      "pullDocument": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "Loho_Documentlocation5096",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullSocialMedia": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "Loho_SocialMedia5102",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "lhcNewsLetter": {
        params:
        {
          "Newsletter_Id": 0,
          "Name": "",
          "Email_Id": "",
          "status": "1",
          "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "userid": 1,
          "rptname": "Newsletter5104",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullCoupon": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "offer_Coupon_15115",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullEvents": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "Event_Master_15132",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "postComfortSpace": {
        params:
        {
          "Comfort_Space_Registation_1_Id": "0",
          "Event_Id": "0",
          "Event_Name": "",
          "Name": "",
          "Email": "",
          "Age": "",
          "Phone": "",
          "Gender": "",
          "Location": "",
          "Mode_of_Payment": "",
          "Group_Description": "",
          "Additional_Comments": "",
          "Events_Amount": "0",
          "Payment_Status": "",
          "status": "",
          "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "userid": "1",
          "rptname": "Comfort_Space_Registation_15137",
          "client": "LOVEandHOPEcompany1393"
        }
      },

      "postSupport_Groups": {
        params: {
          "Support_Groups_Registation_1_Id": "0",
          "Event_Id": "0",
          "Event_Name": "",
          "Name": "",
          "Email": "",
          "Age": "",
          "Phone": "",
          "Gender": "",
          "Location": "",
          "Mode_of_Payment": "",
          "Group_Description": "",
          "Additional_Comments": "",
          "Any_Proir": "",
          "Therapy_before": "",
          "Events_Amount": "0",
          "Payment_Status": "",
          "status": "", "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "userid": "1",
          "rptname": "Support_Groups_Registation_15138",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "postGroup_Therapy": {
        params: {
          "Group_Therapy_Registation_1_Id": "0",
          "Event_Id": "0",
          "Event_Name": "",
          "Name": "",
          "Email": "",
          "Age": "",
          "Phone": "",
          "Gender": "",
          "Location": "",
          "Mode_of_Payment": "",
          "Group_Description": "",
          "Additional_Comments": "",
          "Any_Proir": "",
          "Therapy_before": "",
          "Events_Amount": "0",
          "Payment_Status": "",
          "status": "", "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "userid": "1",
          "rptname": "Group_Therapy_Registation_15139",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "postEventTransaction": {
        params:
        {
          "Events_Transactions_Id": "0",
          "Trans_Ref_id": "",
          "Events_Id": "",
          "Event_Name": "",
          "Registration_Name": "",
          "Group_Type": "",
          "Registration_Id": "",
          "Gw_Trans_Ref_id": "",
          "Gw_trans_Status": "",
          "Date_of_transacion": "",
          "Amount_paid": "",
          "Coupon": "",
          "Modeofpayment": "",
          "Invoice_no": "",
          "Trans_Email_status": "",
          "Trans_Email_date_and_time": "",
          "status": "",
          "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "Transaction_Status": "",
          "userid": "1",
          "rptname": "Events_Transactions5142",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "Loho_ModeTherapy": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "Mode5144",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "postEitaRegistration": {
        params:
        {
          "userid": "1",
          "Event_Registration_Id": " ",
          "Event_Name": " ",
          "Name": " ",
          "Age": " ",
          "Grade": " ",
          "School": " ",
          "ParentName_one": " ",
          "ParentName_two": " ",
          "Mobile_no": " ",
          "Email": " ",
          "Referral_eita": " ",
          "Description": " ",
          "Additional_comments": " ",
          "Event_id": " ",
          "Event_amount": " ",
          "Mode_of_Payment": "",
          "Payment_Status": "",
          "status": "",
          "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "login_user_id": " ",
          "rptname": "Event_Registration5146",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullLhc_Invoice": {
        params:
        {
          Created_By: "",
          Created_date: "1900-01-01",
          LatestInvoiceNumber: "",
          Lhc_Invoice_1_Id: 2,
          Modified_By: "",
          Modified_date: "1900-01-01",
          Tag: "",
          client: "LOVEandHOPEcompany1393",
          rptname: "Lhc_Invoice_15147",
          status: "1",
          userid: "1"
        }
      },
      "pushLhc_Invoice": {
        params:
        {
          "start": 1,
          "end": 1000,
          "userid": "1",
          "rptname": "Lhc_Invoice_15147",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pushInvoiceData": {
        params:
        {
          "Invoice_Report_1_Id": "0",
          "Client_Name_": "",
          "Email_id_": "",
          "Phone_Number": "",
          "Invoice_Number": "",
          "Invoice_Date": "",
          "Service_Type": "",
          "Initial_amount_": "",
          "Additional_amount_": "",
          "Total_amount_": "",
          "Amount_in_words": "",
          "Mode_of_payment_": "",
          "Payment_Ref": "",
          "Hours": "",
          "Additional_Hours_": "",
          "Description_1": "",
          "Description_2": "",
          "status": "",
          "Tag": "",
          "Created_date": "",
          "Created_By": "",
          "Modified_By": "",
          "Modified_date": "",
          "userid": "1",
          "rptname": "Invoice_Report_15149",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullEitaCustomer": {
        params:
        {
          "start": 1,
          "Mobile_no": "",
          "end": 1000,
          "userid": "1",
          "rptname": "Event_Registration5146",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullComfortSpaceregistration": {
        params: {
          "start": 1,
          "Phone": "",
          "end": 1000,
          "userid": "1",
          "rptname": "Comfort_Space_Registation_15137",
          "client": "LOVEandHOPEcompany1393",
        }
      },
      "pullSupportRegistration": {
        params: {
          "start": 1,
          "Phone": "",
          "end": 1000,
          "userid": "1",
          "rptname": "Support_Groups_Registation_15138",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullGroupRegistration": {
        params: {
          "start": 1,
          "Phone": "",
          "end": 1000,
          "userid": "1",
          "rptname": "Group_Therapy_Registation_15139",
          "client": "LOVEandHOPEcompany1393"
        }
      },
      "pullEitaRegistration": {
        params: {
          "start": 1,
          "Event_Registration_Id": "",
          "end": 1000,
          "userid": "1",
          "rptname": "Event_Registration5146",
          "client": "LOVEandHOPEcompany1393"
        }
      }
    }
    this.postaddApi = this.host + 'postadd/';
    this.summaryApi = this.host + 'summary/';
    this.summaryListApi = this.host + 'summarylist/';
  }

  private postApi(url, data, callback) {
    const headers = { "content-type": "application/json" };
    const body = this.srtingifyDataForEpc(data);
    return this._http
      .post(url, body, { headers: headers })
      .map((res: any) => res)
      .subscribe((data) => {
        callback(data);
      });
  }

  pullRequest(indata, callback) {
    this.postApi(this.summaryApi, indata, (respdata) => {
      if (respdata["_POST_SummaryMethodResult"].length > 0) {
        if (respdata["_POST_SummaryMethodResult"] !== "FAIL") {
          callback(JSON.parse(respdata["_POST_SummaryMethodResult"]));
        } else {
          callback(null);
        }
      }
    });
  }

  pullSummaryListRequest(indata, callback) {
    this.postApi(this.summaryListApi, indata, (respdata) => {
      if (respdata["_POST_SummaryListResult"].length > 0) {
        if (respdata["_POST_SummaryListResult"] !== "FAIL") {
          callback(JSON.parse(respdata["_POST_SummaryListResult"]));
        } else {
          callback(null);
        }
      }
    });
  }

  pushRequest(indata, callback) {
    this.postApi(this.postaddApi, indata, (respdata) => {
      if (respdata["_POST_AddMethodResult"].length > 0) {
        var _data = {};
        var _responseData = respdata["_POST_AddMethodResult"][0];
        _data['Result'] = _responseData.Result;
        if (_responseData.Result == "SUCCESS") {
          ////////////////////////////////////
          // callback(_responseData.Result);
          ////////////////////////////////////
          var ref = _responseData.Ref_ID;
          if (ref) {
            ref = eval("(" + ref + ")");
            _data = Object.assign({}, ref);
            callback(_data);
            // for(var m in ref){
            //   _data = Object.assign({},_data,ref[m]);
            // }
          } else {
            callback(_responseData.Result);
          }
        } else if (_responseData.Result == "FAILED" || _responseData.Result == "FAIL") {
          this.respMessage = '';
          if (_responseData.Message) {
            _data = Object.assign({}, _data, JSON.parse(_responseData.Message));
            this.respMessage = _data['Message']
          } else {
            this.respMessage = 'Record Saved Failed!.Please try again..'
          }
          _data = _data["Result"];
          callback(_data);
        }
      }
      // callback(_data);
    })
  }



  customRequest(api, indata, callback) {
    this.postApi(api, indata, (respdata) => {
      callback(respdata);
    });
  }



  private srtingifyDataForEpc(json): string {
    let obj = { data: JSON.stringify(json) };
    let pdata = JSON.stringify(obj);
    return pdata;
  }

  setHeaders() {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let aceessToken = this.configureService.getAccessToken();
    headers.set('ACCESSKEY', 'client')
    headers.set('APIKEY', 'rptname')
    headers.set('TOKEN', aceessToken);
    return headers;
  }
  getDate() {
    this.currentDate = new Date();
    this.genDate = this.datePipe.transform(this.currentDate, this.dateFormat);
  }
}